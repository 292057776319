import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom';
import CenterCard from './CenterCard';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 }
]

const CenterTabPanel = ({ tabName }) => {

  const [specificData, setSpecificData] = useState([]);
  const [data,setData] = useState([])

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem('locale');
      const supportedLocales = ['en-GB', 'sq-AL'];
      const defaultLocale = 'en-GB';
  
      const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
      const api = `/API/OurCenters-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();

      const filteredData = tabName === "All" ? data : data.filter((item) => item.locationSearch === tabName);
      
      setSpecificData(filteredData);
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [localStorage.getItem('language'), tabName]);


  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };
  return (
    <div className='centerTabPanel'>
      <Carousel
       itemPadding={[10, 15]}
       className="centerCarousel"
       breakPoints={breakPoints}
       ref={carouselRef}
       onPrevStart={onPrevStart}
       onNextStart={onNextStart}
       disableArrowsOnEnd={false}
      >
        
        {
          specificData.map((s) => {
            return(
              <CenterCard
                upperImage={`${process.env.REACT_APP_CentersImages_IMAGE}${s.upperImage}`}
                ccardText={s.ccardText}
                ageGroup={s.ageGroup}
                location={s.location}
                policy={s.policy}
                locationIcon={s.locationIcon}
                id={s.id}
              />
            )
          })
        }

      </Carousel>
        
    </div>
  )
}

export default CenterTabPanel
