import React, { useState } from "react";
import "./Amount.scss";
import { CiUser } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { FormattedMessage } from "react-intl";

const Amount = () => {
  const [openAmount, setOpenAmount] = useState(false);

  return (
    <div className="Amount">
      <div className="Amount__1">
        <p className="mediumText">
          <FormattedMessage
            id="Amount__1"
            defaultMessage="Choose how you want to give"
          />
        </p>
        <div className="makePadding">
          <div className="alignItemAmount">
            <input type="radio" name="dontaion" id="oneTime" />
            <label htmlFor="oneTime">
              <FormattedMessage
                id="Amount__2"
                defaultMessage="One time donation"
              />
            </label>
          </div>

          <div>
            <input type="radio" name="dontaion" id="monthlyDonation" />
            <label htmlFor="monthlyDonation">
              <FormattedMessage
                id="Amount__3"
                defaultMessage="Monthly donation"
              />
            </label>
          </div>
        </div>
      </div>

      <div className="Amount__2">
        <p className="mediumText">
          <FormattedMessage id="Amount__4" defaultMessage="Amount" />
        </p>

        <div className="makePadding">
          <p>&euro;20</p>
          <p>&euro;30</p>
          <p>&euro;50</p>
          <p>&euro;80</p>
          <p>&euro;100</p>
        </div>
      </div>

      <div className="Amount__3">
        <div>
          <p className="regularText">
            <FormattedMessage
              id="Amount__5"
              defaultMessage="Or add your preferred amount:"
            />
          </p>
          <p className="regularText">
            <span>&euro;</span>
            <FormattedMessage
              id="Amount__5"
              defaultMessage="Or add your preferred amount:"
            />
          </p>
        </div>
      </div>

      <div className="Amount__4">
        <div className="dedicate" onClick={() => setOpenAmount(!openAmount)}>
          <input
            type="checkbox"
            id="dedicate"
            checked={!openAmount ? openAmount : null}
          />
          <label htmlFor="dedicate">
            <FormattedMessage
              id="Amount__6"
              defaultMessage="Dedicate my donation "
            />
            <span className="yellowA">
              &nbsp;
              <FormattedMessage id="Amount__7" defaultMessage="in honor" />
              &nbsp;
            </span>
            <FormattedMessage id="Amount__8" defaultMessage="or" />

            <span className="yellowA">
              &nbsp;
              <FormattedMessage id="Amount__9" defaultMessage="in memory" />
              &nbsp;
            </span>

            <FormattedMessage id="Amount__10" defaultMessage="of someone" />
          </label>
        </div>

        <input type="text" />
      </div>

      {openAmount && (
        <div className="Amount__5">
          <p className="mediumText">
            <FormattedMessage id="Amount__11" defaultMessage="Type" />
          </p>
          <div className="makePadding">
            <div>
              <input type="radio" name="honor" id="inHonor" />
              <label htmlFor="inHonor">
                <FormattedMessage
                  id="Amount__12"
                  defaultMessage="In honor of..."
                />
              </label>
            </div>

            <div>
              <input type="radio" name="honor" id="inMemory" />
              <label htmlFor="inMemory">
                <FormattedMessage
                  id="Amount__13"
                  defaultMessage="In memory of..."
                />
              </label>
            </div>
          </div>
        </div>
      )}

      {openAmount && (
        <div className="Amount__6">
          <div>
            <label htmlFor="firstName">
              <FormattedMessage
                id="Amount__14"
                defaultMessage="Honoree's First Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input
                type="text"
                id="firstName"
                placeholder="Write first name"
              />
            </div>
          </div>

          <div>
            <label htmlFor="lastName">
              <FormattedMessage
                id="Amount__15"
                defaultMessage="Honoree's Last Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input type="text" id="lastName" placeholder="Write last name" />
            </div>
          </div>
        </div>
      )}

      {openAmount && (
        <div className="Amount__7">
          <div>
            <label htmlFor="email">
              <FormattedMessage
                id="Amount__16"
                defaultMessage="Recipient's email"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <MdOutlineEmail />
              </span>
              <input type="text" id="email" placeholder="Email address here" />
            </div>
            <span className="makeSomeGray">
              <FormattedMessage
                id="Amount__17"
                defaultMessage="Optionally send a notification email"
              />
            </span>
          </div>
        </div>
      )}

      {openAmount && (
        <div className="Amount__8">
          <div>
            <label htmlFor="recipientName">
              <FormattedMessage
                id="Amount__18"
                defaultMessage="Recipient's First Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input
                type="text"
                id="recipientName"
                placeholder="Write first name"
              />
            </div>
          </div>

          <div>
            <label htmlFor="recipientLastname">
              <FormattedMessage
                id="Amount__19"
                defaultMessage="Recipient's Last Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input
                type="text"
                id="recipientLastname"
                placeholder="Write last name"
              />
            </div>
          </div>
        </div>
      )}

      {openAmount && (
        <div className="Amount__9">
          <div>
            <label htmlFor="message">
              <FormattedMessage
                id="Amount__20"
                defaultMessage="Your Message to the Recipient"
              />
            </label>
            <div>
              <span className="message"></span>
              <textarea
                name=""
                id="message"
                cols="30"
                rows="10"
                placeholder="Your message here..."
              ></textarea>
            </div>
          </div>
          <span className="makeSomeGray">
            <FormattedMessage
              id="Amount__21"
              defaultMessage="Please spell check, include how you would like your name(s) signed, mention the amount if desired, and format your message as you would like it delivered."
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default Amount;
