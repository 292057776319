import { FormattedMessage } from 'react-intl'
import aicon1 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon1.svg'
import aicon2 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon2.svg'
import aicon3 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon3.svg'
import aicon4 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon4.svg'
import aicon5 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon5.svg'
import aicon6 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon6.svg'
import aicon7 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon7.svg'
import aicon8 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon8.svg'

export const aboutData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        bgHoverColor: "#FE570B",
        aicon : aicon1,
        title: <FormattedMessage id='aboutData__1' />,
        towher: "aboutus",
        des: <FormattedMessage id='aboutData__2' />,
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        bgHoverColor: "#D22BDE",
        aicon : aicon2,
        title: <FormattedMessage id='aboutData__3' />,
        towher: "ourCourses",
        des: <FormattedMessage id='aboutData__4' />,
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        bgHoverColor: "#FEC801",
        aicon : aicon3,
        title: <FormattedMessage id='aboutData__5' />,
        towher: "ourTeam",
        des: <FormattedMessage id='aboutData__6' />,
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        bgHoverColor: "#2BDEBA",
        aicon : aicon4,
        title: <FormattedMessage id='aboutData__7' />,
        towher: "ourhistory",
        des: <FormattedMessage id='aboutData__8' />,
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        bgHoverColor: "#9066F1",
        aicon : aicon5,
        title: <FormattedMessage id='aboutData__9' />,
        towher: "ourCenters",
        des: <FormattedMessage id='aboutData__10' />,
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        bgHoverColor: "#FF3636",
        aicon : aicon6,
        title: <FormattedMessage id='aboutData__11' />,
        towher: "pricing",
        des: <FormattedMessage id='aboutData__12' />,
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        bgHoverColor: "#B60BFE",
        aicon : aicon7,
        title: <FormattedMessage id='aboutData__13' />,
        des: <FormattedMessage id='aboutData__14' />,
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        bgHoverColor: "#3679FF",
        aicon : aicon8,
        title: <FormattedMessage id='aboutData__15' />,
        towher: "gallery",
        des: <FormattedMessage id='aboutData__16' />,
    }
]