import React from "react";
import "./Apps.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import GetOurApps from "../../components/pages/GetOurApps/GetOurApps";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Apps = () => {
  return (
    <div className="Apps">
      <Navbar />

      <div className="Apps__inside">
        <div className="Apps__1">
          <div className="left">
            <h1 className="ourServiceTitleBg">
              KosovaKids&nbsp;
              <FormattedMessage id="apps__1" defaultMessage={"Mobile App"} />
            </h1>

            <span className="changeColor">
              <FormattedMessage
                id="apps__2"
                defaultMessage={"The app every Parent needs"}
              />
            </span>

            <p className="ourServiceText">
              <FormattedMessage
                id="apps__3"
                defaultMessage={
                  "Download the KosovaKids app to speak with teachers, see your children, pay for services, and much more."
                }
              />
            </p>

            <div className="download">
              <Link to="https://www.apple.com/app-store/" target="_blank" className="app"></Link>
              <Link to="https://play.google.com/store/games?hl=en&pli=1" target="_blank" className="google"></Link>
            </div>
          </div>

          <div className="right">
            <div className="right__apps__1">
              <div className="apps1__star"></div>
              <div className="apps1__wave"></div>
            </div>
          </div>
        </div>

        <div className="Apps__2">
          <div className="left">
            <h1 className="ourServiceTitle">
              <FormattedMessage
                id="apps__4"
                defaultMessage={"Staying connected"}
              />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id="apps__5"
                defaultMessage={
                  "In today's fast-paced world, staying connected with your child's educational journey is more important than ever. Our mobile app bridges the gap between parents, teachers, and students, ensuring you're always in the loop, no matter where you are."
                }
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1">
              <div className="rightInside1__people"></div>
              <div className="apps2__wave"></div>
              <div className="apps2__magnet"></div>
            </div>
          </div>
        </div>

        <div className="Apps__3">
          <div className="left">
            <h1 className="ourServiceTitle">
              <FormattedMessage
                id="apps__6"
                defaultMessage={"Communication at your fingertips"}
              />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id="apps__7"
                defaultMessage={
                  "Our app redefines the way parents communicate with educators. With real-time notifications, you'll never miss an update. From important announcements, event reminders, to your child's daily activities, everything you need to know is just a tap away."
                }
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1">
              <div className="apps3__star"></div>
              <div className="apps3__circle"></div>
            </div>
          </div>
        </div>

        <div className="Apps__4">
          <div className="left">
            <h1 className="ourServiceTitle">
              <FormattedMessage
                id="apps__8"
                defaultMessage={"Interactive learning journeys"}
              />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id="apps__9"
                defaultMessage={
                  "Dive into your child's learning adventures through our app. Discover curated content, view their projects, and track their progress. We incorporated features that allow parents to see their child's milestones, understand their curriculum, and find ways to support learning at home. It's like having a virtual window into their classroom."
                }
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1">
              <div className="apps4__wave"></div>
              <div className="apps4__circle"></div>
            </div>
          </div>
        </div>

        <div className="Apps__5">
          <div className="left">
            <h1 className="ourServiceTitle">
              <FormattedMessage
                id="apps__10"
                defaultMessage={"Secure and Private"}
              />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id="apps__11 "
                defaultMessage={
                  "Your family's privacy and security are our top priorities. The app uses the latest security protocols to protect your information and ensure that only authorized users can access your child's updates."
                }
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1">
              <div className="apps5__verify"></div>
              <div className="apps5__magnet"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Apps;
