import React from 'react'
import './OurCenterAbout.scss'
import { FormattedMessage } from 'react-intl'

const OurCenterAbout = (props) => {

  return (
    <div className='OurCenterAbout'>
        
        <div className="left">
            <div className="wave" ></div>
            <h1>
            
            <FormattedMessage 
              id='OurCenterAbout__1'
              defaultMessage={"About this center"}
            />
            </h1>
            <p>
              {props.aboutThisCenter}
            </p>            
        </div>

        <div className="right">
            <div className="bigImage"></div>
            <div className="blueStar"></div>
            <div className="circle"></div>
            <div className="moon"></div>
            <div className="sky"></div>

        </div>

    </div>
  )
}

export default OurCenterAbout