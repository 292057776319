import React from 'react';
import './OutdoorActivities.scss';
import Navbar from '../../components/shared/Navbar/Navbar';
import Footer from '../../components/shared/Footer/Footer';
import image1 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon1.svg';
import image2 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon2.svg';
import image3 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon3.svg';
import image4 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon4.svg';
import image5 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon5.svg';
import image6 from '../../assets/images/OutdoorActivities/OutdoorActivitiesIcon6.svg';
import DayCareCard2 from '../../components/pages/DayCare/DayCareCard2/DayCareCard2';
import EventsActivities from '../../components/pages/EventsActivities/EventsActivities';
import { FormattedMessage } from 'react-intl';

const OutdoorActivities = () => {
  return (
    <div className='OutdoorActivities'>
      <Navbar />
      <div className="OutdoorActivities__inside">
        <div className="OutdoorActivities__1">
          <div className="OutdoorActivities__1__filter"></div>
          <h1 className='ourServiceTitleBg'> 
            <FormattedMessage id='OutdoorActivities__1' defaultMessage='Outdoor Activities' />
          </h1>
          <p className='ourServiceText'>
            <FormattedMessage id='OutdoorActivities__2' defaultMessage='Join our activities and events' />
          </p>
        </div>

        <div className="OutdoorActivities__2">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='OutdoorActivities__3' defaultMessage='Growing through nature' />
            </h1>
            <p className="ourServiceText">
              <FormattedMessage
                id='OutdoorActivities__4'
                defaultMessage="The benefits of outdoor play extend far beyond physical health. Our Outdoor Activities program is designed to enhance cognitive development, improve social skills, and boost emotional well-being. By challenging themselves physically and mentally, children develop resilience, problem-solving skills, and a sense of accomplishment. Furthermore, regular interaction with nature helps foster a peaceful state of mind and a deep respect for the environment."
              />
            </p>
          </div>
          <div className="right">
            <div className="rightInside rightInside1">
              <div className="outdoorActivities1__star"></div>
            </div>
          </div>
        </div>

        <div className="OutdoorActivities__3">
          <h1 className="ourServiceTitle">
            <FormattedMessage id='OutdoorActivities__5' defaultMessage='A diverse range of activities' />
          </h1>
          <p className="ourServiceText">
            <FormattedMessage
              id='OutdoorActivities__6'
              defaultMessage='From guided nature walks that introduce children to local flora and fauna, to outdoor sports and games that build teamwork and physical fitness, our activities are designed to engage and excite.'
            />
          </p>
          <div className="OutdoorActivities__3__inside">
            <DayCareCard2
              bgImage={image1}
              title={<FormattedMessage id='Eco-Adventures' defaultMessage='Eco-Adventures' />}
              text={<FormattedMessage
                id='OutdoorActivities__7'
                defaultMessage='Discovering the secrets of nature through hands-on environmental education.'
              />}
            />

            {/* Repeat for other cards */}

          </div>

          <h1 className="bigText">
            <FormattedMessage
              id='OutdoorActivities__8'
              defaultMessage="If you're not part of KosovaKids yet, you can still join our upcoming events and activities"
            />
          </h1>
        </div>

        <EventsActivities />
      </div>
      <Footer />
    </div>
  )
}

export default OutdoorActivities;
