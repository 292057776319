import React from 'react'
import './Confirmation.scss'
import { FormattedMessage } from 'react-intl'

const Confirmation = () => {
  return (
    <div className='Confirmation'>
      <h1>
      <FormattedMessage  id='Confirmation__1' defaultMessage={"Thank you very much for your donation"}/>
      </h1>
    </div>
  )
}

export default Confirmation
