import React, { useEffect, useState } from 'react'
import './Shop.scss';
import { Link } from 'react-router-dom';
import { carouselData, shopData } from './ShopData';
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl';

const Shop = () => {

    const breakPoints = [
        { width: 100, itemsToShow: 2 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };

    const [savedShopData, setSavedShopData] = useState(null);

    useEffect(() => {
        const savedData = localStorage.getItem('shopSaved');
        if (savedData) {
          try {
            const { data, timestamp } = JSON.parse(savedData);
            const now = new Date().getTime();
            if (now - timestamp <= 30 * 60 * 1000) {
              setSavedShopData(data);
            } else {
              localStorage.removeItem('shopSaved');
              setSavedShopData(null); // Clear saved data if expired
            }
          } catch (error) {
            console.error('Error parsing saved data:', error);
            localStorage.removeItem('shopSaved'); // Remove corrupted data
            setSavedShopData(null);
          }
        }
      }, []);
      
      useEffect(() => {
        if (!savedShopData) {
          const serializedData = JSON.stringify({
            data: shopData,
            timestamp: new Date().getTime()
          });
          try {
            localStorage.setItem('shopSaved', serializedData);
            setSavedShopData(shopData);
          } catch (error) {
            console.error('Error saving data to localStorage:', error);
          }
        }
      }, [savedShopData]);
      
      // New useEffect to delete automation after 1 minute
      useEffect(() => {
        const deleteAutomation = setTimeout(() => {
          localStorage.removeItem('shopSaved');
          setSavedShopData(null);
        }, 60000); // 1 minute in milliseconds
      
        return () => clearTimeout(deleteAutomation); // Cleanup function to clear timeout on component unmount
      }, []);

  return (
    <div className='aboutMegamenu' >
        <div className='aboutLeft'>
            <div className='aboutLeftText'>
                <p className='extraShop'>
                  <FormattedMessage id='extraShop__1'/>
                  </p>
            </div>
            <div className='aboutLeftCards'>
                {
                    shopData.map((ab,index) =>{
                        return(
                            <Link className={`aboutMegamenuCard ${ab.class}`}>
                                <div className='acardHolderImg'>
                                        <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                                </div>
                                <div className='acardRightContent'>
                                    <p className='acardTitle'>{ab.title}</p>
                                    <p className='acardText'>{ab.des}</p>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
        <div className='coursesRight'>
                <div className='aboutRightText'>
                    <p>
                    <FormattedMessage id='extraShop__2'/>
                    </p>
                </div>
                <Carousel
                itemPadding={[10, 10]}
                className="expCarousel"
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
                >
                        {
                            carouselData.map((cd,index) => {
                                return(
                                    <Link className='coursesCard' key={index}>
                                        <div className='shopLogo'></div>
                                       <div className='imageHolder'>
                                            <div className='courseCardImg' style={{ content: `url(${cd.carouselImg})` }}></div>
                                       </div>
                                        <div className='carouselDown'>
                                            <p className='pName'>{cd.itemName}</p>
                                            <p className='pPrice'>{cd.itemPrice}</p>
                                        </div>
                                    </Link>
                                )
                            })
                        }

                </Carousel>

                <div className='shopButton button'>
                    <Link>
                    <FormattedMessage id='extraShop__3'/>
                    </Link>
                </div>
        </div>
    </div>
  )
}

export default Shop
