import React, { useState } from 'react';
import "./KosovaKidsCenter.scss";
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MdArrowRightAlt } from "react-icons/md";
import { FormattedMessage } from "react-intl";

const KosovaKidsCenter = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Tabs className='kosovaKidsCenter' selectedIndex={selectedIndex} onSelect={handleSelect}>
      <div className='kkcLeft'>
        <div className='kkcHeading'>
          <p><FormattedMessage id="kkc3-1"/> <span className='offerRed'><FormattedMessage id="kkc3-2"/></span></p>
        </div>
        <div className='kkctablist'>
          <TabList className="kkctbl">
            <Tab className={`kkct ${selectedIndex === 0 ? 'active' : ''}`}>
              <p><FormattedMessage id="kkcTab1"/></p>
            </Tab>
            <Tab className={`kkct ${selectedIndex === 1 ? 'active' : ''}`}>
              <p><FormattedMessage id="kkcTab2"/></p>
            </Tab>
            <Tab className={`kkct ${selectedIndex === 2 ? 'active' : ''}`}>
              <p><FormattedMessage id="kkcTab3"/></p>
            </Tab>
          </TabList>
        </div>
        <div className='kkclD'>
          {selectedIndex === 0 && <p><FormattedMessage id="kkc1"/>&nbsp;</p>}
          {selectedIndex === 1 && <p><FormattedMessage id="Preschool__text"/>&nbsp;</p>}
          {selectedIndex === 2 && <p><FormattedMessage id="Daycare__text"/>&nbsp;</p>}
        </div>
        <div className='learnMore'>
          <p><FormattedMessage id="kkc2"/></p>
          <div className='arrowCircle'>
            <MdArrowRightAlt className='aciconar'/>
          </div>
        </div>
      </div>
      <div className='kkcRight'>
        <div className='starYellowkkc kkcicon'></div>
        <div className='starGreenkkc kkcicon'></div>
        <div className='kkcWave kkcicon'></div>
        <div className='kkcMagnet kkcicon'></div>
        <TabPanel>
          {selectedIndex === 0 && <div className='kkcTabPanelImg1'></div>}
        </TabPanel>
        <TabPanel>
          {selectedIndex === 1 && <div className='kkcTabPanelImg2'></div>}
        </TabPanel>
        <TabPanel>
          {selectedIndex === 2 && <div className='kkcTabPanelImg3'></div>}
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default KosovaKidsCenter;
