import React from 'react'
import './AboutUs.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from "react-intl"
import { Helmet } from 'react-helmet';

const AboutUs = ({ intl }) => {
  const title = intl.formatMessage({ id: 'myTitle__AboutUs' });

  return (
    <div className='aboutUs'>
            <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className='abouUsLeft'>
        {/* <div className='anotherBorder'></div>
        <div className='aboutLeftHolder'>
            <div className='aboutLeftImg'></div>
            <div className='aboutLeftBorder'></div>
        </div> */}
        <div className="insideAboutLeft">
        </div>
      </div>
      <div className='aboutSRight'>
        <div className='aboutUsHeader'>
            <p>
            <FormattedMessage id="abu1"  />
              </p>
        </div>
        <div className='aboutUsText'>
             <p>
             <FormattedMessage id="abu2"  />
              </p>
        </div>
        <div className='aboutUsSchedule'>
            <div className='aschL'>
              <div className='aschlIcon'></div>
              <p>
              <FormattedMessage id="abu3"  />
                </p>
            </div>
            <div className='aschR'>
              <p>
              <FormattedMessage id="abu4"  />
                </p>
            </div>
        </div>
        <div className='aboutUsButton button'>
            <Link to="/aboutus">
            <FormattedMessage id="abu5-link"  />
            </Link>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(AboutUs)
