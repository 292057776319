import React from "react";
import "./OurHistory.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const OurHistory = () => {
  return (
    <div className="OurHistory">
      <Navbar />

      <div className="OurHistory__inside">
        <div className="OurHistory__1">
          <div className="OurHistory__1__filter"></div>
          <h1>
            <FormattedMessage
              id="OurHistory__0"
              defaultMessage={"Our History"}
            />
          </h1>
          <div className="OurHistory__1__rightImage"></div>
        </div>

        <div className="OurHistory__padding">
          <div className="OurHistory__2">
            <div className="left">
              <div className="imageHistory image2">
                <div className="s1magnet"></div>
                <div className="s1wave"></div>
                <div className="s1star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">
                <FormattedMessage id="OurHistory__1" />
              </h1>

              <p className="pText">
                <FormattedMessage id="OurHistory__2" />
              </p>
            </div>
          </div>

          <div className="OurHistory__3">
            <div className="OurHistoryText left">
              <h1 className="title">
                <FormattedMessage id="OurHistory__3" />
              </h1>

              <p className="pText">
                <FormattedMessage id="OurHistory__4" />
              </p>

              <Link className="ourHistoryLink">
                <FormattedMessage id="OurHistory__5" />
              </Link>
            </div>

            <div className="OurHistoryText right">
              <div className="imageHistory image3">
                <div className="s2circle"></div>
                <div className="s2circles"></div>
                <div className="s2star"></div>
              </div>
            </div>
          </div>

          <div className="OurHistory__4">
            <div className="left">
              <div className="imageHistory image4">
                <div className="s3magnet"></div>
                <div className="s3star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">
                <FormattedMessage id="OurHistory__6" />
              </h1>

              <p className="pText">
                <FormattedMessage id="OurHistory__7" />
              </p>
            </div>
          </div>

          <div className="OurHistory__5">
            <div className="OurHistoryText left">
              <h1 className="title">
                <FormattedMessage id="OurHistory__8" />
              </h1>

              <p className="pText">
                <FormattedMessage id="OurHistory__9" />
              </p>

              <Link className="ourHistoryLink">
                <FormattedMessage id="OurHistory__10" />
              </Link>
            </div>

            <div className="OurHistoryText right">
              <div className="imageHistory image5">
                <div className="s4circle"></div>
                <div className="s4magnet"></div>
                <div className="s4wave"></div>
              </div>
            </div>
          </div>

          <div className="OurHistory__6">
            <div className="left">
              <div className="imageHistory image6">
                <div className="s5circle"></div>
                <div className="s5star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">
                <FormattedMessage id="OurHistory__11" />
              </h1>

              <p className="pText">
                <FormattedMessage id="OurHistory__12" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurHistory;
