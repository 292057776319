import React from 'react'
import './FreeWorkshop.scss'
import { Link } from 'react-router-dom'

const FreeWorkshop = ({
    bgImage,
    smallTitle,
    smallTitleColor,
    smallTitle2,
    description,
    age,
    location,
    dataAndTime
}) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

    const smallTitleColorDiv = {
        backgroundColor: `${smallTitleColor}`
    }


  return (
    <div className='FreeWorkshop'>
        <div className="top" style={bgImageDiv}></div>

        <div className="bottom">
            <div className="moveLink">
            <div className="bottom__1" >
                <p className='withBgColor' style={smallTitleColorDiv}>
                    {smallTitle}
                </p>

                <p className='FreeWorkshopAddBorder'>
                    {smallTitle2}
                </p>
            </div>

            <p className='FreeWorkshop__description'>
                {description}
            </p>

            <div className="bottom__2">
            <div className="bottom__age">
                    <div className="ageIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Age group
                        </span>

                        <p>
                        {age}
                        </p>
                    </div>
                </div>

                <div className="bottom__age">
                    <div className="locationIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Location
                        </span>

                        <p>
                        {location}
                        </p>
                    </div>
                </div>

                <div className="bottom__age">
                    <div className="durationIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Duration
                        </span>

                        <p>
                        {dataAndTime}
                        </p>
                    </div>
                </div>
            </div>
            </div>

            <Link className='FreeCoursesCard1__A'>
            Enroll
            </Link>


        </div>


    </div>
  )
}

export default FreeWorkshop