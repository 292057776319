import React, { useEffect, useState } from 'react'
import './Courses.scss';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import { carouselDataC, coursesData } from './CoursesData';
import { FormattedMessage } from 'react-intl';
import profile1 from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/carouselProfile.webp';

const Courses = () => {
    const breakPoints = [
        { width: 100, itemsToShow: 1 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };




    const [savedCoursesData, setSavedCoursesData] = useState(null);

    useEffect(() => {
      const savedData = localStorage.getItem('coursesSaved');
      if (savedData) {
        try {
          const { data, timestamp } = JSON.parse(savedData);
          const now = new Date().getTime();
          if (now - timestamp <= 30 * 60 * 1000) {
            setSavedCoursesData(data);
          } else {
            localStorage.removeItem('coursesSaved');
            setSavedCoursesData(null); // Clear saved data if expired
          }
        } catch (error) {
          console.error('Error parsing saved data:', error);
          localStorage.removeItem('coursesSaved'); // Remove corrupted data
          setSavedCoursesData(null);
        }
      }
    }, []);
  
    useEffect(() => {
      if (!savedCoursesData) {
        const serializedData = JSON.stringify({
          data: coursesData,
          timestamp: new Date().getTime()
        });
        try {
          localStorage.setItem('coursesSaved', serializedData);
          setSavedCoursesData(coursesData);
        } catch (error) {
          console.error('Error saving data to localStorage:', error);
        }
      }
    }, [savedCoursesData]);



    const [coursesDataSlider, setCoursesDataSlider] = useState([])
    const fetchData = async () => {
        try {
          const storedLocale = localStorage.getItem("locale");
          const supportedLocales = ["en-GB", "sq-AL"];
          const defaultLocale = "en-GB";
          const locale = supportedLocales.includes(storedLocale)
            ? storedLocale
            : defaultLocale;
          const api = `/API/OurCourses-${locale}.json`;
    
          const response = await fetch(api);
          const data = await response.json();
          setCoursesDataSlider(data);
        }   
        catch (error) {
            console.error("OurCourses : ", error);
          }
      };

      useEffect(() => {
        fetchData();
      }, [localStorage.getItem("language")]);



  return (
    <div className='aboutMegamenu'>
    <div className='aboutLeft'>
        <div className='aboutLeftText'>
            <p><FormattedMessage id="aboutMegamenu__1__2" /></p>
        </div>
        <div className='aboutLeftCards'>
            {
                coursesData.map((ab,index) =>{
                    return(
                        <Link to={ab.toWhere} className={`aboutMegamenuCard ${ab.class}`}>
                            <div className='acardHolderImg'>
                                    <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                            </div>
                            <div className='acardRightContent'>
                                <p className='acardTitle'>{ab.title}</p>
                                <p className='acardText'>{ab.des}</p>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    </div>
    <div className='coursesRight'>
            <div className='aboutRightText'>
                <p>
                    <FormattedMessage id='coursesRight__1'/>
                </p>
            </div>
            <Carousel
            itemPadding={[10, 10]}
            className="expCarousel"
            breakPoints={breakPoints}
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
            >
                    {
                        coursesDataSlider.slice(0,5).map((cd,index) => {
                            return(
                                <div className='coursesSCard' key={index}>
                                    <div className='pApr button' style={{ backgroundColor: `${cd.bgColor}` }}>
                                        <p className='pforKids' >{cd.childish}</p>
                                    </div>
                                    <div className='coursesCardImg' style={{ content: `url(${process.env.REACT_APP_OurCoursesImages_IMAGE}/${cd.upperImage})` }}></div>
                                    <div className='coursesCDown'>
                                        <div className='uppperCoursesCard'>
                                            <p className='cpro'>{cd.childish}</p>
                                            <p className='cpri'>{cd.totalPrice}</p>
                                        </div>
                                        <div className='coursesContentDown'>
                                            <div className='ccdl'>
                                                <div className='cprofile' style={{ content: `url(${profile1})` }}></div>
                                                <div className='cprofdt'>
                                                    <p className='cprofdtR'>{cd.instructor}</p>
                                                    <p className='cprofdtRN'>{cd.name}</p>
                                                </div>
                                            </div>
                                            <div className='ccdr'>
                                                <div className='clockImg' style={{ content: `url(${cd.clockIcon})` }}></div>
                                                <div className='ccdrR'>
                                                    <p className='csch'><FormattedMessage id="coursesData__11"/></p>
                                                    <p className='ctime'>{cd.starAndEntTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

            </Carousel>

            <div className='shopButton button'>
                <Link>
                    <FormattedMessage id='coursesRight__2'/>
                </Link>
            </div>
    </div>
</div>
  )
}

export default Courses
