import React from 'react'
import './DayCareCard2.scss'

const DayCareCard2 = ({ bgImage, title, text }) => {

    const bgConst = {
        content: `url(${bgImage})`
      }

  return (
    <div className='DayCareCard2'>
        <div className="DayCareCard2__image" style={bgConst}>

        </div>
        <h1 className="DayCareCard2Title">
        {title}
        </h1>

        <p className="DayCareCard2Text">
            {text}
        </p>

    </div>
  )
}

export default DayCareCard2