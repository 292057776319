import React, { useEffect, useState } from "react";
import "./CheckOurCourses.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import axios from "axios";
import {
  IoIosArrowDown,
  IoIosArrowDropdown,
  IoIosArrowUp,
  IoIosSearch,
} from "react-icons/io";
import { MdKeyboardArrowDown, MdSort } from "react-icons/md";

import CoursesCard from "../../components/pages/OurCourses/CoursesCard";
import { Link } from "react-router-dom";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const CheckOurCourses = ({ tabName, intl }) => {
  const [pageData, setPageData] = useState([]);
  const [originalPageData, setOriginalPageData] = useState([]);

  const location = useLocation();

  const [perPageNumber] = useState(6);
  const [sortOrder, setSortOrder] = useState("az");

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem("locale");
      const supportedLocales = ["en-GB", "sq-AL"];
      const defaultLocale = "en-GB";

      const locale = supportedLocales.includes(storedLocale)
        ? storedLocale
        : defaultLocale;
      const api = `/API/OurCourses-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();

      const filteredData =
        tabName === "All"
          ? data
          : data.filter((item) => item.locationSearch === tabName);

      setPageData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [localStorage.getItem("language"), tabName]);

  useEffect(() => {
    const updateStateFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const categories = searchParams.get("categories")?.split("&") || ["Others"];
      const durations = searchParams.get("durations")?.split(",") || [];
      const ages = searchParams.get("ages")?.split("&") || [];
      const locations = searchParams.get("locations")?.split("&") || [];
      const page = parseInt(searchParams.get("page"), 10) || 1;
  
      setSelectedCategory(categories);
      setSeletedDuration(durations);
      setSeletedAge(ages);
      setSelectedLocation(locations);
      setCurrentPage(page);
    };
  
    updateStateFromUrl();
  }, [location.search]);


  const [openLocation, setOpenLocation] = useState(false);
  const locationData = [
    "Pristina",
    "Peja",
    "Ferizaj",
    "Prizren",
    "Gjilan",
    "Gjakovë",
    "Deçan",
    "Dragash",
    "Drenas",
    "Fushë Kosovë",
    "Istog",
    "Kaçanik",
    "Kamenicë",
    "Klinë",
    "Lipjan",
    "Malishevë",
    "Mitrovicë",
    "Obiliq",
    "Podujevë",
    "Rahovec",
    "Skenderaj",
    "Suharekë",
    "Shtërpcë",
    "Shtime",
    "Viti",
    "Vushtrri",
  ];

  const [selectedCategory, setSelectedCategory] = useState(["Others"]);
  const handleCategorySelect = (category) => {
    if (category) {
      const updatedSelectedCategory = selectedCategory.includes(category)
        ? selectedCategory.filter((selected) => selected !== category)
        : [...selectedCategory, category];
      setSelectedCategory(updatedSelectedCategory);
      // Update URL with selected categories only
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("categories", updatedSelectedCategory.join("&"));
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    } else {
      setSelectedCategory([]);
      // Update URL with empty categories
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("categories");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  };

  const updateSelectedCategoriesFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const categoriesParam = searchParams.get("categories");
    const categoriesArray = categoriesParam
      ? categoriesParam.replace(/\+/g, " ").split("&")
      : ["Others"];
    setSelectedCategory(categoriesArray);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurCourses-en-GB.json`)
      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);
      })
      .catch((error) => {
        console.error("OurCourses : ", error);
      });

    // Update selected categories from URL when URL changes
    updateSelectedCategoriesFromURL();
  }, []);

  useEffect(() => {
    // Update selected categories from URL when URL changes
    updateSelectedCategoriesFromURL();
  }, []);

  const [selectedDuration, setSeletedDuration] = useState([]);
  const handleDurationSelect = (duration) => {
    if (duration) {
      const updatedSelectedDuration = selectedDuration.includes(duration)
        ? selectedDuration.filter((selected) => selected !== duration)
        : [...selectedDuration, duration];
      setSeletedDuration(updatedSelectedDuration);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("durations", updatedSelectedDuration.join(","));
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    } else {
      setSeletedDuration([]);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("durations");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  };

  const [selectedAge, setSeletedAge] = useState([]);
  const handleAgeSelect = (age) => {
    if (age) {
      const updatedSelectedAge = selectedAge.includes(age)
        ? selectedAge.filter((selected) => selected !== age)
        : [...selectedAge, age];
      setSeletedAge(updatedSelectedAge);
      // Update URL with selected ages only
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("ages", updatedSelectedAge.join("&"));
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams
          .toString()
          .replace(/%26/g, "&")}`
      );
    } else {
      setSeletedAge([]);
      // Update URL with empty ages
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("ages");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams
          .toString()
          .replace(/%26/g, "&")}`
      );
    }
  };

  const [selectedLocation, setSelectedLocation] = useState([]);
  const handleLocationSelect = (location) => {
    if (location) {
      const updatedSelectedLocation = selectedLocation.includes(location)
        ? selectedLocation.filter((selected) => selected !== location)
        : [...selectedLocation, location];
      setSelectedLocation(updatedSelectedLocation);
      // Update URL with selected locations only
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("locations", updatedSelectedLocation.join("&"));
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams
          .toString()
          .replace(/%26/g, "&")}`
      );
    } else {
      setSelectedLocation([]);
      // Update URL with empty locations
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("locations");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams
          .toString()
          .replace(/%26/g, "&")}`
      );
    }
  };

  const [openCategories, setOpenCategories] = useState(true);
  const [openLocationFilter, setOpenLocationFilter] = useState(true);
  const [openDuration, setOpenDuration] = useState(true);
  const [openAge, setOpenAge] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const filteredData = pageData.filter(
    (item) =>
      (selectedCategory.includes("Others") ||
        selectedCategory.includes(item.category)) &&
      (selectedDuration.length === 0 ||
        selectedDuration.includes(item.duration)) &&
      (selectedAge.length === 0 || selectedAge.includes(item.age)) &&
      (selectedLocation.length === 0 || selectedLocation.includes(item.city))
  );

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredData = originalPageData.filter((item) =>
      item.ccardText.toLowerCase().includes(term)
    );
    setPageData(filteredData);
  };

  // Sort
  const handleSort = (property) => {
    const sortedData = [...pageData].sort((a, b) => {
      if (sortOrder === "az") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });

    setPageData(sortedData);
    setSortOrder(sortOrder === "az" ? "za" : "az");

    // Update URL with sorting order
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("sort", sortOrder === "az" ? "za" : "az");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  };
  // End Sort

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  const npage = Math.ceil(filteredData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    // Update current page based on the selected location
    if (currentPage > 1) {
      setCurrentPage(currentPage);
      // Update URL with selected location
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", currentPage);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    } else {
      // Reset current page to the first page
      setCurrentPage(1);
      // Remove the page parameter from the URL
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("page");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  }, [currentPage]);

  const numbersToShow = 5;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const filteredAndPaginatedData = filteredData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );



  const title = intl.formatMessage({ id: 'myTitle__ourCourse' });

  useEffect(() => {
    fetchData();
  }, [selectedCategory, selectedDuration, selectedAge, selectedLocation]);


  return (
    <div className="CheckOurCourses">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <Navbar />

      <div className="CheckOurCourses__inside">
        <div className="CheckOurCourses__top">
          <div className="CheckOurCourses__image"></div>
          <div className="CheckOurCourses__filter"></div>

          <h1>
            <FormattedMessage
              id="CheckOurCourses__0"
              defaultMessage={"Courses"}
            />
          </h1>

          <div className="CheckOurCourses__star"></div>
          <div className="CheckOurCourses__magnet"></div>
        </div>

        <div className="CheckOurCourses__bottom">
          <div className="CheckOurCourses__bottom__1">
            <div className="wwoUp">
              <p>
                <FormattedMessage
                  id="CheckOurCourses__1"
                  defaultMessage={"Check Our"}
                />

                <span className="offerRed">
                  &nbsp;{" "}
                  <FormattedMessage
                    id="CheckOurCourses__0"
                    defaultMessage={"Courses"}
                  />
                </span>
              </p>
            </div>

            <div className="inside__tab">
              <div className="blogpage__search">
                <span>
                  <IoIosSearch />
                </span>
                <input
                  type="search"
                  placeholder="Search blog"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>

              <div className="blogpage__sortby">
                <span>
                  <MdSort />
                </span>
                <FormattedMessage
                  id="CheckOurCourses__2"
                  defaultMessage={"Sort by"}
                />
                <span className="arrow">
                  <MdKeyboardArrowDown />
                </span>
                <div
                  className="openOnHover__sortBy"
                  onClick={() => handleSort("title")}
                >
                  <p className={`${sortOrder ? "backColor" : ""}`}>A - Z</p>
                  <p className={`${!sortOrder ? "backColor" : ""}`}>Z - A</p>
                </div>
              </div>
            </div>
          </div>

          <div className="CheckOurCourses__bottom__2">
            <div
              className="forMobile"
              onClick={() => setOpenFilter(!openFilter)}
            >
              <p>
                <FormattedMessage
                  id="CheckOurCourses__3"
                  defaultMessage={"Filters"}
                />

                <IoIosArrowDropdown
                  className={`${openFilter ? "" : "rotateSvg"}`}
                />
              </p>
            </div>

            <div
              className={`CheckOurCourses__bottom__2__left  ${
                openFilter ? "openFilter" : "closeFilter"
              }`}
            >
              <div className="bottomEducation__left__options bottomEducation__left__1">
                <h2 onClick={() => setOpenCategories(!openCategories)}>
                  <FormattedMessage
                    id="CheckOurCourses__4"
                    defaultMessage={"Category"}
                  />
                </h2>

                <ul
                  className={`mobileEducation ${
                    openCategories ? "hide" : "show"
                  }`}
                >
                  <li>
                    <input
                      type="checkbox"
                      id="Robotics"
                      checked={selectedCategory.includes("Robotics")}
                      onChange={() => handleCategorySelect("Robotics")}
                    />
                    <label htmlFor="Robotics">
                      <FormattedMessage
                        id="CheckOurCourses__5"
                        defaultMessage={"Robotics & IT"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="Design"
                      checked={selectedCategory.includes("Design")}
                      onChange={() => handleCategorySelect("Design")}
                    />
                    <label htmlFor="Design">
                      <FormattedMessage
                        id="CheckOurCourses__6"
                        defaultMessage={"Design"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="Programming"
                      checked={selectedCategory.includes("Programming")}
                      onChange={() => handleCategorySelect("Programming")}
                    />
                    <label htmlFor="Programming">
                      <FormattedMessage
                        id="CheckOurCourses__7"
                        defaultMessage={"Programming"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="Science"
                      checked={selectedCategory.includes("Science")}
                      onChange={() => handleCategorySelect("Science")}
                    />
                    <label htmlFor="Science">
                      <FormattedMessage
                        id="CheckOurCourses__8"
                        defaultMessage={"Science"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="Soft Skills"
                      checked={selectedCategory.includes("Soft Skills")}
                      onChange={() => handleCategorySelect("Soft Skills")}
                    />
                    <label htmlFor="Soft Skills">
                      <FormattedMessage
                        id="CheckOurCourses__9"
                        defaultMessage={"Soft Skills"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="Others"
                      checked={selectedCategory.includes("Others")}
                      onChange={() => handleCategorySelect("Others")}
                    />
                    <label htmlFor="Others">
                      <FormattedMessage
                        id="CheckOurCourses__10"
                        defaultMessage={"Others"}
                      />
                    </label>
                  </li>
                </ul>
              </div>

              <div className="bottomEducation__left__options bottomEducation__left__1">
                <h2 onClick={() => setOpenDuration(!openDuration)}>
                  <FormattedMessage
                    id="CheckOurCourses__11"
                    defaultMessage={"duration"}
                  />
                </h2>

                <ul
                  className={`mobileEducation ${
                    openDuration ? "hide" : "show"
                  }`}
                >
                  <li>
                    <input
                      type="checkbox"
                      id="weeks1to4"
                      checked={selectedDuration.includes("1-4 weeks")}
                      onChange={() => handleDurationSelect("1-4 weeks")}
                    />
                    <label htmlFor="weeks1to4">
                      <FormattedMessage
                        id="CheckOurCourses__12"
                        defaultMessage={"1-4 weeks"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="months1to3"
                      checked={selectedDuration.includes("1-3 months")}
                      onChange={() => handleDurationSelect("1-3 months")}
                    />
                    <label htmlFor="months1to3">
                      <FormattedMessage
                        id="CheckOurCourses__13"
                        defaultMessage={"1-3 months"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="more3months"
                      checked={selectedDuration.includes("More than 3 months")}
                      onChange={() =>
                        handleDurationSelect("More than 3 months")
                      }
                    />
                    <label htmlFor="more3months">
                      <FormattedMessage
                        id="CheckOurCourses__14"
                        defaultMessage={"More than 3 months"}
                      />
                    </label>
                  </li>
                </ul>
              </div>

              <div className="bottomEducation__left__options bottomEducation__left__1">
                <h2 onClick={() => setOpenAge(!openAge)}>
                  <FormattedMessage
                    id="CheckOurCourses__15"
                    defaultMessage={"age"}
                  />
                </h2>

                <ul className={`mobileEducation ${openAge ? "hide" : "show"}`}>
                  <li>
                    <input
                      type="checkbox"
                      id="yo5to7"
                      checked={selectedAge.includes("5-7 year old")}
                      onChange={() => handleAgeSelect("5-7 year old")}
                    />
                    <label htmlFor="yo5to7">
                      <FormattedMessage
                        id="CheckOurCourses__16"
                        defaultMessage={"5-7 year old"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="yo8to11"
                      checked={selectedAge.includes("8-11 year old")}
                      onChange={() => handleAgeSelect("8-11 year old")}
                    />
                    <label htmlFor="yo8to11">
                      <FormattedMessage
                        id="CheckOurCourses__17"
                        defaultMessage={"8-11 year old"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="yo11to13"
                      checked={selectedAge.includes("11-13 year old")}
                      onChange={() => handleAgeSelect("11-13 year old")}
                    />
                    <label htmlFor="yo11to13">
                      <FormattedMessage
                        id="CheckOurCourses__18"
                        defaultMessage={"11-13 year old"}
                      />
                    </label>
                  </li>

                  <li>
                    <input
                      type="checkbox"
                      id="yo14to16"
                      checked={selectedAge.includes("14-16 year ols")}
                      onChange={() => handleAgeSelect("14-16 year ols")}
                    />
                    <label htmlFor="yo14to16">
                      <FormattedMessage
                        id="CheckOurCourses__19"
                        defaultMessage={"14-16 year old"}
                      />
                    </label>
                  </li>
                </ul>
              </div>

              <div className="bottomEducation__left__options bottomEducation__left__1">
                <h2 onClick={() => setOpenLocationFilter(!openLocationFilter)}>
                  <FormattedMessage
                    id="CheckOurCourses__20"
                    defaultMessage={"Location"}
                  />
                </h2>

                <ul
                  className={`mobileEducation ${
                    openLocationFilter ? "hide" : "show"
                  }`}
                >
                  {locationData.slice(0, 5).map((location, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        id={location}
                        checked={selectedLocation.includes(location)}
                        onChange={() => handleLocationSelect(location)}
                      />
                      <label htmlFor={location}>{location}</label>
                    </li>
                  ))}

                  <li
                    onClick={() => setOpenLocation(!openLocation)}
                    className={`orange loadMore ${
                      openLocation ? "close" : "open"
                    }`}
                  >
                    <Link
                      to=""
                      className={`orange loadMore ${
                        openLocation ? "close" : "open"
                      }`}
                    >
                      <FormattedMessage
                        id="CheckOurCourses__21"
                        defaultMessage={"See more"}
                      />

                      <IoIosArrowDown />
                    </Link>
                  </li>

                  {locationData
                    .slice(8, locationData.length)
                    .map((location, index) => (
                      <li
                        key={index}
                        className={`location ${
                          !openLocation ? "close" : "open"
                        } `}
                      >
                        <input
                          type="checkbox"
                          id={location}
                          checked={selectedLocation.includes(location)}
                          onChange={() => handleLocationSelect(location)}
                        />
                        <label htmlFor={location}>{location}</label>
                      </li>
                    ))}

                  <li
                    onClick={() => setOpenLocation(!openLocation)}
                    className={`orange loadMore ${
                      openLocation ? "open" : "close"
                    }`}
                  >
                    <Link to="" className="orange">
                      <FormattedMessage
                        id="CheckOurCourses__22"
                        defaultMessage={"See less"}
                      />
                      <IoIosArrowUp />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="CheckOurCourses__bottom__2__right">
              {filteredAndPaginatedData.length == 0 ? (
                <p className="noItems">
                  <FormattedMessage
                    id="CheckOurCourses__23"
                    defaultMessage={"No results"}
                  />
                </p>
              ) : (
                <div className="CheckOurCourses__bottom__2__right__filter">
                  {filteredAndPaginatedData.map((item) => (
                    <CoursesCard
                      upperImage={`${process.env.REACT_APP_OurCoursesImages_IMAGE}${item.upperImage}`}
                      ccardText={item.ccardText}
                      ageGroup={item.ageGroup}
                      location={item.location}
                      policy={item.policy}
                      locationIcon={item.locationIcon}
                      paid={item.paid}
                      childish={item.childish}
                      bgColor={item.bgColor}
                      duration={item.duration}
                      category={item.category}
                      city={item.city}
                      age={item.age}
                      id={item.id}
                      instructor={item.instructor}
                    />
                  ))}
                </div>
              )}

              <div className="paginationInCourses">
                {filteredData.length > 0 ? (
                  <div className="bottomEducation__right__bottom">
                    <ul className="bottom__numbers">
                      <p onClick={prePage} className="arrowPagination">
                        <FaArrowLeftLong />
                      </p>

                      {getPageNumbers().map((n) => (
                        <li
                          key={n}
                          className={`page-item ${
                            currentPage === n ? "activeBottom" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={() => changeCPage(n)}
                            className="page-link"
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <p onClick={nextPage} className="arrowPagination">
                        <FaArrowRightLong />
                      </p>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default injectIntl(CheckOurCourses);
