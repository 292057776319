import React, { useEffect, useState } from 'react'
import './Services.scss';
import { SCarouselData, servicesData } from './ServicesData';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl';

const Services = () => {

    const breakPoints = [
        { width: 100, itemsToShow: 1 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };

    const [savedServicesData, setSavedServicesData] = useState(null);

    useEffect(() => {
      const savedData = localStorage.getItem('servicesSaved');
      if (savedData) {
        try {
          const { data, timestamp } = JSON.parse(savedData);
          const now = new Date().getTime();
          if (now - timestamp <= 30 * 60 * 1000) {
            setSavedServicesData(data);
          } else {
            localStorage.removeItem('servicesSaved');
            setSavedServicesData(null); // Clear saved data if expired
          }
        } catch (error) {
          console.error('Error parsing saved data:', error);
          localStorage.removeItem('servicesSaved'); // Remove corrupted data
          setSavedServicesData(null);
        }
      }
    }, []);
  
    useEffect(() => {
      if (!savedServicesData) {
        const serializedData = JSON.stringify({
          data: servicesData,
          timestamp: new Date().getTime()
        });
        try {
          localStorage.setItem('servicesSaved', serializedData);
          setSavedServicesData(servicesData);
        } catch (error) {
          console.error('Error saving data to localStorage:', error);
        }
      }
    }, [savedServicesData]);


  return (
    <div className='aboutMegamenu'>
    <div className='aboutLeft'>
        <div className='aboutLeftText'>
            <p>
              
              <FormattedMessage id='aboutMegamenu__1' />  
            </p>
        </div>
        <div className='aboutLeftCards'>
            {
                servicesData.map((ab,index) =>{
                    return(
                        <Link className={`aboutMegamenuCard ${ab.class}`} to={`/${ab.towher}`}>
                            <div className='acardHolderImg'>
                                    <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                            </div>
                            <div className='acardRightContent'>
                                <p className='acardTitle'>{ab.title}</p>
                                <p className='acardText'>{ab.des}</p>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    </div>
    
    <div className='coursesRight'>
            <div className='aboutRightText'>
                <p>
              <FormattedMessage id='aboutMegamenu__2' />  
                </p>
            </div>
            <Carousel
            itemPadding={[10, 10]}
            className="expCarousel"
            breakPoints={breakPoints}
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
            >
                    {
                        SCarouselData.map((cd,index) => {
                            return(
                                <div className='coursesSCard' key={index}>
                                    <div className='coursesCardImg' style={{ backgroundImage: `url(${cd.carouselImg})` }}></div>
                                    <div className='coursesCDown'>
                                       <p className='sTitle'>{cd.title}</p>
                                       <p className='sDes'>{cd.des}</p>
                                    </div>
                                </div>
                            )
                        })
                    }

            </Carousel>

            <div className='shopButton button'>
                <Link>
                <FormattedMessage id='aboutMegamenu__3' />  
              </Link>
            </div>
    </div>
</div>
  )
}

export default Services
