import React, { useEffect, useState } from 'react'
import './OurGallery.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const OurGallery = () => {

    const useApiData = (apiUrl) => {
        const [data, setData] = useState([]);
      
        const fetchData = async () => {
          try {
            const response = await fetch(apiUrl);
            const responseData = await response.json();
            setData(responseData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        useEffect(() => {
          fetchData();
        }, []);
      
        return data;
      };
      
        const data1 = useApiData('../API/OurGallery1.json');
        const data2 = useApiData('../API/OurGallery2.json');
        const data3 = useApiData('../API/OurGallery3.json');
      

  return (
    <div className='ourGallery'>
       <div className='eventsActivities-heading'>
        {/* <p><FormattedMessage id="og1"  /> <span className='yellowA'><FormattedMessage id="og2"/></span></p> */}

        <p>
            <FormattedMessage 
                id='ourGallery__2__1'
                defaultMessage={"Our Gallery"}
            />
        </p>

      </div>
      <Tabs className="eventsActivitiesDown">
        <div className='eaTablist'>
        <TabList className="eactbl">
                    <Tab className="eact">
                        <p><FormattedMessage id="tab1-1"/></p>
                    </Tab>
                    <Tab className="eact">
                        <p><FormattedMessage id="ogT1"/></p>
                    </Tab>
                    <Tab className="eact">
                        <p><FormattedMessage id="ogT2"/></p>
                    </Tab>
            </TabList>
        </div>

        <div className='galleryTabPanels'>
            <TabPanel className='gallery'>
                {
                    data1.map((i,index) => {
                        return(
                            <div className='galleryImageHolder' key={index}>
                                <div className='galleryImg' style={{ content: `url(${i.image})` }}></div>
                            </div>
                        )
                    })
                }
            </TabPanel>
            <TabPanel className='gallery'>
                {
                    data2.map((i,index) => {
                        return(
                            <div className='galleryImageHolder' key={index}>
                                <div className='galleryImg' style={{ content: `url(${i.image})` }}></div>
                            </div>
                        )
                    })
                }
            </TabPanel>
            <TabPanel className='gallery'>
                {
                    data3.map((i,index) => {
                        return(
                            <div className='galleryImageHolder' key={index}>
                                <div className='galleryImg' style={{ content: `url(${i.image})` }}></div>
                            </div>
                        )
                    })
                }
            </TabPanel>


        </div>
        </Tabs>
        <div className='currButtonHolder'>
        <div className='currButton button'>
          <Link to='/aboutus/gallery'><FormattedMessage id="centersLink"/></Link>
        </div>
    </div>
    </div>
  )
}


export default OurGallery