import React from "react";
import "./OurCenterHeader.scss";
import { IoMdStar } from "react-icons/io";
import { BsBank } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";

const OurCenterHeader = (props) => {
  const circleImage = {
    backgroundImage: `url(${props.upperImage})`,
  };

  return (
    <div className="OurCenterHeader">
      <div className="backToMain">
        <Link to="/locations">
          <FaArrowLeftLong />
          <FormattedMessage
            id="OurCenterHeader__1"
            defaultMessage={"Back To Centers"}
          />
        </Link>
      </div>

      <div className="OurCenterHeader__filter"></div>

      <div className="OurCenterHeader__left">
        <div className="circleInside" style={circleImage}>
          <div className="yellowCircle"></div>

          <div className="stars">
            <IoMdStar />
            {props.rating}
          </div>
        </div>
      </div>

      <div className="OurCenterHeader__right">
        <h1>{props.name}</h1>

        <p>{props.ccardText}</p>

        <div className="topCards">
          <div className="topCard age">
            <BsBank />

            <div className="pandspan">
              <p>
                <FormattedMessage
                  id="OurCenterHeader__2"
                  defaultMessage={"Age group"}
                />
              </p>

              <span>{props.ageGroup}</span>
            </div>
          </div>

          <div className="topCard location">
            <GrLocation />

            <div className="pandspan">
              <p>
                <FormattedMessage
                  id="OurCenterHeader__3"
                  defaultMessage={"Location"}
                />
              </p>

              <span>{props.address}</span>
            </div>
          </div>

          <div className="topCard contact">
            <FiPhone />

            <div className="pandspan">
              <p>
                <FormattedMessage
                  id="OurCenterHeader__4"
                  defaultMessage={"Contact"}
                />
              </p>

              <span>{props.number}</span>
            </div>
          </div>
        </div>
      </div>

      <Link to="/apply" className="enrollNow">
        <FormattedMessage
          id="OurCenterHeader__5"
          defaultMessage={"Enroll now"}
        />
      </Link>
    </div>
  );
};

export default OurCenterHeader;
