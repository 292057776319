import React from 'react';
import './StayAfterRegular.scss';
import Navbar from '../../components/shared/Navbar/Navbar';
import Footer from '../../components/shared/Footer/Footer';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const StayAfterRegular = () => {
  return (
    <div className='StayAfterRegular'>
      <Navbar />

      <div className="StayAfterRegular__inside">
        <div className="WeekEndStay__1">
          <div className="WeekEndStay__1__filter"></div>
          <h1 className="ourServiceTitleBg">
            <FormattedMessage id='StayAfterRegular__1' defaultMessage='Stay After Regular Schedule' />
          </h1>

          <p className="ourServiceText">
            <FormattedMessage
              id='StayAfterRegular__2'
              defaultMessage='Designed for families who need additional coverage in the afternoon and early evening'
            />
          </p>
        </div>

        <div className="WeekEndStay__2">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='StayAfterRegular__3' defaultMessage='Our extended day program' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='StayAfterRegular__4'
                defaultMessage="We understand the challenges of juggling work, family, and the unexpected turns of daily life. That's why we've created our 'Stay After Regular Schedule' program, offering a supportive, engaging, and safe environment for your child beyond the usual school hours. Designed for families who need additional coverage in the afternoon and early evening, our program ensures that every child continues to learn, play, and grow, even after the regular day ends."
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1">
              <div className="rightInside1__wave1"></div>
              <div className="rightInside1__wave2"></div>
            </div>
          </div>
        </div>

        <div className="WeekEndStay__3">
          <div className="left">
            <div className="rightInside leftInside3">
              <div className="leftInside3__star1"></div>
              <div className="leftInside3__star2"></div>
              <div className="leftInside3__Image"></div>
            </div>
          </div>

          <div className="right">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='StayAfterRegular__5' defaultMessage='Caring for their whole well-being' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='StayAfterRegular__6'
                defaultMessage="We understand the importance of nutrition and downtime in a child's busy day. Our program includes nutritious snacks to refuel and refresh your child, followed by periods of relaxation and free play. This balanced approach ensures that children stay energized, happy, and ready to engage in our enriching activities."
              />
            </p>
          </div>
        </div>


        <div className="WeekEndStay__4">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='StayAfterRegular__7' defaultMessage='Accommodating your schedule' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='StayAfterRegular__8'
                defaultMessage="Recognizing the varying demands of modern life, our program offers flexible pickup times to accommodate the needs of every family. Whether you need an extra hour or a more extended stay, we're here to ensure your child is safe and engaged until you're ready to pick them up."
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside3">
              <div className="rightInside3__stay3__1"></div>
              <div className="rightInside3__stay3__2"></div>
            </div>
          </div>
        </div>


        <div className="WeekEndStay__6">
          <h1 className="ourServiceTitle">
            <FormattedMessage id='StayAfterRegular__9' defaultMessage='Your partner in childcare and development' />
          </h1>

          <p className="ourServiceText">
            <FormattedMessage
              id='StayAfterRegular__10'
              defaultMessage="Let KosovaKids' be your partner in managing the complexities of modern parenting with our 'Stay After Regular Schedule' program. We're dedicated to providing a safe, enriching, and flexible after-school solution that supports your child's learning and development while accommodating your family's schedule. Contact us to learn more about this essential service and how we can tailor it to meet your needs."
            />
          </p>

          <Link>
            <FormattedMessage id='StayAfterRegular__11' defaultMessage='Contact us' />
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default StayAfterRegular;
