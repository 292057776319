import React, { useEffect, useState } from 'react'
import './FreeCourses.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import FreeCoursesCard1 from '../../components/pages/FreeCourses/FreeCoursesCard1/FreeCoursesCard1'
import axios from 'axios'
import FreeWorkshop from '../../components/pages/FreeCourses/FreeWorkshop/FreeWorkshop'
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram, FaTiktok } from "react-icons/fa6";
import { FaSnapchatSquare } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const FreeCourses = () => { 
  const [freeCourses, setFreeCourses] = useState([]);
  const [freeWorkshop, setFreeWorkshops] = useState([]);

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem("locale");
      const supportedLocales = ["en-GB", "sq-AL"];
      const defaultLocale = "en-GB";
      const locale = supportedLocales.includes(storedLocale)
        ? storedLocale
        : defaultLocale;
      const api = `/API/FreeCourses-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();
      setFreeCourses(data);
    } catch (error) {
      console.error("FreeCourses: ", error);
    }
  };

  const fetchDataWorkshop = async () => {
    try {
      const storedLocale = localStorage.getItem("locale");
      const supportedLocales = ["en-GB", "sq-AL"];
      const defaultLocale = "en-GB";
      const locale = supportedLocales.includes(storedLocale)
        ? storedLocale
        : defaultLocale;
      const api = `/API/FreeWorkshop-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();
      setFreeWorkshops(data);
    } catch (error) {
      console.error("FreeWorkshop: ", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataWorkshop();
  }, [localStorage.getItem("language")]);




  return (
    <div className='FreeCourses'>
      <Navbar />
      <div className="FreeCourses__index">
        <div className="FreeCourses__1">
          <div className="WeekEndStay__1__filter"></div>
          <h1 className="ourServiceTitleBg">
            <FormattedMessage id='FreeCourses__1' defaultMessage='Free Courses & Workshops' />
          </h1>
          <p className="ourServiceText">
            <FormattedMessage id='FreeCourses__2' defaultMessage='Best courses for kids to get started' />
          </p>
        </div>

        <div className="FreeCourses__2">
          <h1 className="ourServiceTitle">
            <FormattedMessage id='FreeCourses__3' defaultMessage='Free courses' />
          </h1>
          <p className='ourServiceText'>
            <FormattedMessage id='FreeCourses__4' defaultMessage='Join our upcoming free courses' />
          </p>
          <div className="FreeCourses__2__inside">
            {
              freeCourses.map((item) => (
                <FreeCoursesCard1 
                  key={item.id}                  
                  bgImage={`${process.env.REACT_APP_FreeCoursesCard_IMAGE}${item.bgImage}`}
                  smallTitle={item.smallTitle}
                  smallTitleColor={item.smallTitleColor}
                  title={item.title}
                  age={item.age}
                  location={item.location}
                  duration={item.duration}
                  time={item.time}
                />
              ))
            }
          </div>
        </div>

        <div className="FreeCourses__3">
          <h1 className="ourServiceTitle">
            <FormattedMessage id='FreeCourses__5' defaultMessage='Free workshops' />
          </h1>
          <p className='ourServiceText'>
            <FormattedMessage
              id='FreeCourses__6'
              defaultMessage='Participate in our upcoming free workshops for kids and parents.'
            />
          </p>
          <div className="FreeCourses__3__inside">
            {
              freeWorkshop.map((item) => (
                <FreeWorkshop
                  key={item.id}                  
                  bgImage={`${process.env.REACT_APP_FreeWorkshop_IMAGE}${item.bgImage}`}
                  smallTitle={item.smallTitle}
                  smallTitleColor={item.smallTitleColor}
                  smallTitle2={item.smallTitle2}
                  description={item.description}
                  age={item.age}
                  location={item.location}
                  dataAndTime={item.dataAndTime}
                />
              ))
            }
          </div>
        </div>

        <div className="FreeCourses__4">
          <h1 className='ourServiceTitle'>
            <FormattedMessage
              id='FreeCourses__7'
              defaultMessage='Follow us in social media to always be informed for new courses and workshops'
            />
          </h1>
          <div className="socials">
            <Link to="https://www.facebook.com/kosovakidscom">
              <FaFacebookSquare />
            </Link>
            <Link to="https://www.instagram.com/kosovakids/">
              <FaSquareInstagram />
            </Link>
            <Link>
              <FaSnapchatSquare />
            </Link>
            <Link to="https://x.com/Kosovakids">
              <FaSquareTwitter />
            </Link>
            <Link to="https://linkedin.com/kosovakids">
              <FaLinkedin />
            </Link>
            <Link to="https://tiktok.com/@kosovakids">
              <FaTiktok />
            </Link>
          </div>
          <p className="kosovaKidsText">
            <FormattedMessage id='FreeCourses__8' defaultMessage='@kosovakids' />
          </p>
          <div className="FreeCourses__4__circle1"></div>
          <div className="FreeCourses__4__circle2"></div>
          <div className="FreeCourses__4__circle3"></div>
          <div className="FreeCourses__4__circle4"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FreeCourses