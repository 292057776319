import React from 'react'
import './KosovaKidsTab.scss'
import NumbersRising from '../NumbersRising/NumbersRising'
import { FormattedMessage } from 'react-intl'

const KosovaKidsTab = () => {
  return (
    <div className='KosovaKidsTab'>
        <div className="KosovaKidsTab__1">
            <div className="aboutUsHeader">
                <p>
                KosovaKids 
                <span className='yellowA'>
                    Center
                </span>
                </p>
            </div>

            <div className="aboutUsText">
                <p>
                <FormattedMessage id='KosovaKidsTab__1'/>
                </p>
            </div>
        </div>

        <div className="KosovaKidsTab__2">
            <div className="KosovaKidsTab__2__filter"></div>
            <div className="insideBorder">
                <div className="recordIcon"></div>
            </div>
        </div>

        <div className="KosovaKidsTab__3">
            <NumbersRising />
        </div>
        

    </div>
  )
}

export default KosovaKidsTab