import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { socialsNavbar } from "./NavbarData";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown, IoIosPhonePortrait } from "react-icons/io";
import About from "./MegaMenus/About/About";
import MobNav from "./MobNav/MobNav";
import Shop from "./MegaMenus/Shop/Shop";
import Courses from "./MegaMenus/Courses/Courses";
import Services from "./MegaMenus/Services/Services";
import ChangingMode from "../ChangingMode";
import SelectLanguage from "../SelectLanguage/SelectLanguage";
import ColorSwitch2 from "../Footer/ColorSwitch2";
import { FormattedMessage } from "react-intl";

const Navbar = (props) => {
  const [mobNav, setMobNav] = useState(false);
  const [aboutM, setAboutM] = useState(false);
  const [shop, setShop] = useState(false);
  const [courses, setCourses] = useState(false);
  const [services, setServices] = useState(false);
  const [setting, setSetting] = useState(false);

  const toggleNavSetting = () => {
    setSetting(!setting);
  };

  const toogleServices = () => {
    setServices(!services);
  };
  const toggleAbout = () => {
    setAboutM(!aboutM);
  };
  const toogleCourses = () => {
    setCourses(!courses);
  };
  const toggleShop = () => {
    setShop(!shop);
  };

  const handleNav = () => {
    setMobNav(!mobNav);
  };

  const location = useLocation();

  const getNavLinkClass = (path) => {
    if (path === "/") {
      return location.pathname === "/" ? "nava activeNava" : "nava";
    }
    return location.pathname.startsWith(path) ? "nava activeNava" : "nava";
  };


  return (
    <div className="navbar">
      <div className="topNavbar">
        <div className="topNavbar-left">
          <div className="topNavbar-info">
            <div className="envelopeIcon"></div>
            <p>info@kosovakids.com</p>
          </div>
          <div className="topNavbar-info">
            <div className="phoneIcon"></div>
            <p>+383 (45) 476 560</p>
          </div>

          <Link to="/apps" className="topNavbar-info">
            <div className="appsIcon"></div>
            <p>Apps</p>
          </Link>
        </div>

        <div className="topNavbarRight">
          {/* <div className="searchIconNavbar"></div> */}
          <div
            className="navbarSettings"
            onMouseEnter={toggleNavSetting}
            onMouseLeave={() => setSetting(false)}
          >
            <div className="navSetting"></div>
            <IoIosArrowDown className="navSetARR" />
            {setting && (
              <div className="settingNavbar">
                <p>MODE:</p>
                <div className="lightDarkNavSetting">
                  <p>Light mode</p>
                  <ChangingMode />
                  <p>Dark mode</p>
                </div>
                <div className="navSettingTheme">
                  <p>THEME:</p>
                  <ColorSwitch2 />
                </div>
              </div>
            )}
          </div>
          <SelectLanguage
            setLanguage={props.setLanguage}
            language={props.language}
          />
          <div className="navbarSocials">
            {socialsNavbar.map((s, index) => {
              return (
                <Link
                  className="socialNavbarIcon"
                  to={s.to}
                  style={{ content: `url(${s.social})` }}
                  key={index}
                ></Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="downNavbar">
        <Link to="/" className="logoHolder">
          <div className="logoIcon"></div>
        </Link>

        <div className="navLinks">
          <div className="navLink">
            <Link to="/" className={getNavLinkClass('/')}>
              <FormattedMessage id="nav1" />
            </Link>
          </div>

          <div className="navLink">
            <Link to="/locations" className={getNavLinkClass('/locations')}>
              <FormattedMessage id="nav1__2" />
            </Link>
          </div>

          <div
            className="navLink megamenu"
            onMouseEnter={toggleAbout}
            onMouseLeave={() => setAboutM(false)}
          >
            <Link to="/aboutus" className={getNavLinkClass('/aboutus')}>
              <FormattedMessage id="nav2" />
            </Link>
            <IoIosArrowDown className="arrowDownNav" />

            {aboutM && <About />}
          </div>

          <div
            className="navLink megamenu"
            onMouseEnter={toogleCourses}
            onMouseLeave={() => setCourses(false)}
          >
            <Link to="/ourCourses" className={getNavLinkClass('/ourCourses')}>
              <FormattedMessage id="nav3" />
            </Link>
            <IoIosArrowDown className="arrowDownNav" />
            {courses && <Courses />}
          </div>

          <div
            className="navLink megamenu"
            onMouseEnter={toogleServices}
            onMouseLeave={() => setServices(false)}
          >
            <Link className="nava">
              <FormattedMessage id="nav4" />
            </Link>
            <IoIosArrowDown className="arrowDownNav" />
            {services && <Services />}
          </div>

          {/* <div
            className="navLink megamenu"
            onMouseEnter={toggleShop}
            onMouseLeave={() => setShop(false)}
          >
            <Link className="nava">
              <FormattedMessage id="nav5" />
            </Link>
            <IoIosArrowDown className="arrowDownNav" />
            {shop && <Shop />}
          </div> */}

          <div className="navLink">
            <Link  className={getNavLinkClass('/blog')} to="/blog">
              <FormattedMessage id="nav6" />
            </Link>
          </div>
          {/* <div className="navLink">
            <Link className="nava" to="/donate">
              <FormattedMessage id="nav7" />
            </Link>
          </div> */}
        </div>

        <div className="downNavbarRight">
          <div className="heartCart">
            <Link to="/favourites" className="navbarHeart"></Link>
            {/* <Link className="cartNavbar"></Link> */}
          </div>
          <Link to="/contactUs" className="contactUs">
            <FormattedMessage id="nav8" />
          </Link>
          <Link className="navbarButton1 button" to="/apply">
            <FormattedMessage id="nav9" />
          </Link>
        </div>
        <div className="hamburger-menu" onClick={handleNav}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        {mobNav && <MobNav setMobNav={setMobNav} />}
      </div>
    </div>
  );
};

export default Navbar;
