import React from "react";
import "./DayCare.scss";
import Footer from "../../components/shared/Footer/Footer";
import Navbar from "../../components/shared/Navbar/Navbar";
import DayCareCard1 from "../../components/pages/DayCare/DayCareCard1/DayCareCard1";

import card1__1 from "../../assets/images/DayCare/card1__1.svg";
import card1__2 from "../../assets/images/DayCare/card1__2.svg";

import card2__1 from "../../assets/images/DayCare/card2__1.svg";
import card2__2 from "../../assets/images/DayCare/card2__2.svg";
import card2__3 from "../../assets/images/DayCare/card2__3.svg";
import card2__4 from "../../assets/images/DayCare/card2__4.svg";
import card2__5 from "../../assets/images/DayCare/card2__5.svg";
import card2__6 from "../../assets/images/DayCare/card2__6.svg";
import card2__7 from "../../assets/images/DayCare/card2__7.svg";
import card2__8 from "../../assets/images/DayCare/card2__8.svg";

import card3__1 from "../../assets/images/DayCare/bigCard1.webp";
import card3__2 from "../../assets/images/DayCare/bigCard2.webp";

import card3__1__icon1 from "../../assets/images/DayCare/DayWave.svg";
import card3__1__icon2 from "../../assets/images/DayCare/DayStar.svg";
import card3__1__icon3 from "../../assets/images/DayCare/DayMagnet.svg";

import card3__2__icon1 from "../../assets/images/DayCare/Day2Circle.svg";
import card3__2__icon2 from "../../assets/images/DayCare/Day2Star.svg";
import card3__2__icon3 from "../../assets/images/DayCare/Day2Magnet.svg";

import DayCareCard2 from "../../components/pages/DayCare/DayCareCard2/DayCareCard2";
import DayCareCard3 from "../../components/pages/DayCare/DayCareCard3/DayCareCard3";
import { FormattedMessage } from "react-intl";

const DayCare = () => {
  return (
    <div className="DayCare">
      <Navbar />
      <div className="WeekEndStay__inside DayCare__inside">
        <div className="WeekEndStay__1 DayCare__1">
          <div className="WeekEndStay__1__filter"></div>
          <h1 className="ourServiceTitleBg">
            <FormattedMessage id="DayCare__1" />
          </h1>

          <p className="ourServiceText">
            <FormattedMessage id="DayCare__2" />
          </p>
        </div>

        <div className="DayCarePadding">
          <DayCareCard1
            bgImage={card1__1}
            text1={<FormattedMessage id="DayCare__3" />}
            text2={<FormattedMessage id="DayCare__4" />}
            text3={<FormattedMessage id="DayCare__5" />}
          />

          <div className="DayCare__2">
            <DayCareCard2
              bgImage={card2__1}
              title={<FormattedMessage id="DayCare__6" />}
              text={<FormattedMessage id="DayCare__7" />}
            />

            <DayCareCard2
              bgImage={card2__2}
              title={<FormattedMessage id="DayCare__8" />}
              text={<FormattedMessage id="DayCare__9" />}
            />

            <DayCareCard2
              bgImage={card2__3}
              title={<FormattedMessage id="DayCare__10" />}
              text={<FormattedMessage id="DayCare__11" />}
            />

            <DayCareCard2
              bgImage={card2__4}
              title={<FormattedMessage id="DayCare__12" />}
              text={<FormattedMessage id="DayCare__13" />}
            />
          </div>

          <DayCareCard3
            bgImage={card3__1}
            icon1={card3__1__icon1}
            icon2={card3__1__icon2}
            icon3={card3__1__icon3}
            title={<FormattedMessage id="DayCare__14" />}
            text={<FormattedMessage id="DayCare__15" />}
          />

          <DayCareCard1
            bgImage={card1__2}
            text1={<FormattedMessage id="DayCare__16" />}
            text2={<FormattedMessage id="DayCare__17" />}
            text3={<FormattedMessage id="DayCare__18" />}
          />

          <div className="DayCare__2">
            <DayCareCard2
              bgImage={card2__5}
              title={<FormattedMessage id="DayCare__19" />}
              text={<FormattedMessage id="DayCare__20" />}
            />

            <DayCareCard2
              bgImage={card2__6}
              title={<FormattedMessage id="DayCare__21" />}
              text={<FormattedMessage id="DayCare__22" />}
            />

            <DayCareCard2
              bgImage={card2__7}
              title={<FormattedMessage id="DayCare__23" />}
              text={<FormattedMessage id="DayCare__24" />}
            />

            <DayCareCard2
              bgImage={card2__8}
              title={<FormattedMessage id="DayCare__25" />}
              text={<FormattedMessage id="DayCare__26" />}
            />
          </div>

          <DayCareCard3
            bgImage={card3__2}
            icon1={card3__2__icon1}
            icon2={card3__2__icon2}
            icon3={card3__2__icon3}
            title={<FormattedMessage id="DayCare__27" />}
            text={<FormattedMessage id="DayCare__28" />}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DayCare;
