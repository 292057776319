import React from 'react';
import './HalfDayCare.scss';
import Footer from '../../components/shared/Footer/Footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/shared/Navbar/Navbar';
import { FormattedMessage } from 'react-intl';

const HalfDayCare = () => {
  return (
    <div className='HalfDayCare'>
      <Navbar />
      <div className="WeekEndStay__inside NightStay__inside HalfDayCare__inside">
        <div className="WeekEndStay__1 NightStay__1 HalfDayCare__1">
          <div className="WeekEndStay__1__filter "></div>
          <h1 className="ourServiceTitleBg">
            <FormattedMessage id='HalfDayCare__1' defaultMessage='Half Day Care' />
          </h1>
          <p className="ourServiceText">
            <FormattedMessage id='HalfDayCare__2' defaultMessage='Perfect for parents who work part-time, study, or need some time to attend to personal tasks.' />
          </p>
        </div>

        <div className="WeekEndStay__2 NightStay__2 HalfDayCare__2">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='HalfDayCare__3' defaultMessage='A perfect solution for busy families' />
            </h1>
            <p className="ourServiceText">
              <FormattedMessage
                id='HalfDayCare__4'
                defaultMessage="Our Half Day Care program at KosovaKids offers the perfect solution for families in need of part-time care. With a focus on flexibility, education, and the overall well-being of your child, we're here to support your family's needs and enrich your child's day. Contact us to learn more about our Half Day Care options and how we can tailor our program to fit your schedule."
              />
            </p>
          </div>
          <div className="right">
            <div className="rightInside rightInside1 nightRight1 HalfDayCareRight1">
              <div className="HalfDayCare1__star"></div>
              <div className="HalfDayCare1__circle"></div>
              <div className="HalfDayCare1__image"></div>
            </div>
          </div>
        </div>

        <div className="WeekEndStay__3 NightStay__3 HalfDayCare__3">
          <div className="left">
            <div className="rightInside leftInside3 nightRight2 HalfDayCareRight2">
              <div className="HalfDayCare2__wave"></div>
              <div className="HalfDayCare2__magnet"></div>
              <div className="HalfDayCare2__image"></div>
            </div>
          </div>
          <div className="right">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='HalfDayCare__5' defaultMessage='Morning and afternoon sessions' />
            </h1>
            <p className="ourServiceText">
              <FormattedMessage
                id='HalfDayCare__6'
                defaultMessage="Our Half Day Care program is tailored to fit your schedule, offering both morning and afternoon sessions. Morning sessions kick start the day with energizing activities and learning opportunities, while afternoon sessions focus on winding down through creative play, storytime, and relaxation activities. This flexibility allows parents to choose the option that best suits their family's routine."
              />
            </p>
          </div>
        </div>

        <div className="WeekEndStay__4 NightStay__4 HalfDayCare__4">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='HalfDayCare__7' defaultMessage='A focus on learning and development' />
            </h1>
            <p className="ourServiceText">
              <FormattedMessage
                id='HalfDayCare__8'
                defaultMessage="Even within the shorter time frame of half-day care, we prioritize educational enrichment. Our curriculum is designed to stimulate cognitive development, creativity, and social skills through a variety of activities, including art projects, music sessions, outdoor play, and interactive learning games. Each session is crafted to ensure children receive a balanced and enriching experience."
              />
            </p>
          </div>
          <div className="right ">
            <div className="rightInside rightInside3 nightRight3 HalfDayCareRight3">
              <div className="HalfDayCare3__circle"></div>
              <div className="HalfDayCare3__star"></div>
            </div>
          </div>
        </div>

        <div className="WeekEndStay__6 HalfDayCare__6">
          <h1 className="ourServiceTitle">
            <FormattedMessage id='HalfDayCare__9' defaultMessage='The perfect fit for your family' />
          </h1>
          <p className="ourServiceText">
            <FormattedMessage
              id='HalfDayCare__10'
              defaultMessage="Whether you're looking for care during the morning buzz or a calm afternoon retreat for your child, KosovaKids' Half Day Care program offers the perfect blend of flexibility, education, and nurturing care. Reach out to us to learn more about how our Half Day Care program can fit into your family's lifestyle, providing your child with a meaningful and joyful experience."
            />
          </p>
          <Link >
            <FormattedMessage id='HalfDayCare__11' defaultMessage='Contact us' />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HalfDayCare;
