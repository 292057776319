import React, { useEffect, useState } from 'react'
import './About.scss';
import { aboutData } from './AboutData';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const About = () => {
    const [savedAboutData, setSavedAboutData] = useState(null);

    useEffect(() => {
      const savedData = localStorage.getItem('aboutSaved');
      if (savedData) {
        try {
          const { data, timestamp } = JSON.parse(savedData);
          const now = new Date().getTime();
          if (now - timestamp <= 30 * 60 * 1000) {
            setSavedAboutData(data);
          } else {
            localStorage.removeItem('aboutSaved');
            setSavedAboutData(null); // Clear saved data if expired
          }
        } catch (error) {
          console.error('Error parsing saved data:', error);
          localStorage.removeItem('aboutSaved'); // Remove corrupted data
          setSavedAboutData(null);
        }
      }
    }, []);
  
    useEffect(() => {
      if (!savedAboutData) {
        const serializedData = JSON.stringify({
          data: aboutData,
          timestamp: new Date().getTime()
        });
        try {
          localStorage.setItem('aboutSaved', serializedData);
          setSavedAboutData(aboutData);
        } catch (error) {
          console.error('Error saving data to localStorage:', error);
        }
      }
    }, [savedAboutData]);

  return (
    <div className='aboutMegamenu'>
        <div className='aboutLeft'>
            <div className='aboutLeftText'>
                <p>
                  <FormattedMessage id='aboutMegamenu__1__about'/>
                </p>
            </div>
            <div className='aboutLeftCards'>
                {
                    aboutData.map((ab) =>{
                        return(
                            <Link className={`aboutMegamenuCard ${ab.class}`} to={`/${ab.towher}`}>
                                <div className='acardHolderImg'>
                                        <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                                </div>
                                <div className='acardRightContent'>
                                    <p className='acardTitle'>{ab.title}</p>
                                    <p className='acardText'>{ab.des}</p>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
        <div className='aboutRight'>
                <div className='aboutRightText'>
                    <p>
                      
                      <FormattedMessage id='aboutRight__1' />
                    </p>
                </div>
                <div className='aboutRightImgHolder'>
                    <div className='aboutRightImg'></div>
                </div>
                <div className='aboutRightMiddle'>
                    <p>
                      
                      <FormattedMessage id='aboutRight__2' />
                    </p>
                </div>
                <div className='aboutRightDown'>
                    <p>
                      
                      <FormattedMessage id='aboutRight__3' />
                    </p>
                </div>
                <div className='navbarButton1 aboutRightBtn button'>
                    <Link to="/apply">
                    <FormattedMessage id='aboutRight__4' />
                    </Link>
                </div>
        </div>
    </div>
  )
}

export default About
