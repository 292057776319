import React from "react";
import "./OurCenterPlanPrograms.scss";
import { FormattedMessage } from "react-intl";

const OurCenterPlanPrograms = (props) => {
  return (
    <div className="OurCenterPlanPrograms">
      <div className="wwoUp">
        <p>
          <FormattedMessage id="OurCenterAbout__2" defaultMessage={"Plan"} />
          <span className="offerRed">
            &nbsp;
            <FormattedMessage
              id="OurCenterAbout__3"
              defaultMessage={"programs"}
            />
          </span>
        </p>
      </div>

      <div className="cards">
        <div className="card">
          <div className="topCard ">{props.planprogram1}</div>
          <div className="bottomCard">
            <p className="planProgramDescription">
              {props.planprogram1Description}
            </p>

            <div className="bottomActivites">
              <h3 className="activites">
                <FormattedMessage
                  id="OurCenterAbout__4"
                  defaultMessage={"Activities:"}
                />
              </h3>

              <p>
                <span className="activites activites1"></span>

                <FormattedMessage
                  id="OurCenterAbout__5"
                  defaultMessage={"Enhancing sensory development"}
                />
              </p>

              <p>
                <span className="activites activites2"></span>

                <FormattedMessage
                  id="OurCenterAbout__6"
                  defaultMessage={"Development of motor skills"}
                />
              </p>

              <p>
                <span className="activites activites3"></span>

                <FormattedMessage
                  id="OurCenterAbout__7"
                  defaultMessage={"Music and movement"}
                />
              </p>

              <p>
                <span className="activites activites4"></span>

                <FormattedMessage
                  id="OurCenterAbout__8"
                  defaultMessage={"Storytelling, puppet shows, and sing-alongs"}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="topCard purple__topCard">{props.planprogram2}</div>
          <div className="bottomCard">
            <p className="planProgramDescription">
              {props.planprogram2Description}
            </p>

            <div className="bottomActivites">
              <h3 className="activites">
                <FormattedMessage
                  id="OurCenterAbout__4"
                  defaultMessage={"Activities:"}
                />
              </h3>

              <p>
                <span className="activites activites5"></span>

                <FormattedMessage
                  id="OurCenterAbout__9"
                  defaultMessage={"Hands-on learning"}
                />
              </p>

              <p>
                <span className="activites activites6"></span>

                <FormattedMessage
                  id="OurCenterAbout__10"
                  defaultMessage={"Alphabet games, story time and reading"}
                />
              </p>

              <p>
                <span className="activites activites7"></span>

                <FormattedMessage
                  id="OurCenterAbout__11"
                  defaultMessage={"Group projects and role-play games"}
                />
              </p>

              <p>
                <span className="activites activites8"></span>

                <FormattedMessage
                  id="OurCenterAbout__12"
                  defaultMessage={"Art projects and singing classes"}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="topCard green__topCard">{props.planprogram3}</div>
          <div className="bottomCard">
            <p className="planProgramDescription">
              {props.planprogram3Description}
            </p>

            <div className="bottomActivites">
              <h3 className="activites">
                <FormattedMessage
                  id="OurCenterAbout__4"
                  defaultMessage={"Activities:"}
                />
              </h3>

              <p>
                <span className="activites activites1"></span>

                <FormattedMessage
                  id="OurCenterAbout__13"
                  defaultMessage={"Preparing for school activities"}
                />
              </p>

              <p>
                <span className="activites activites2"></span>

                <FormattedMessage
                  id="OurCenterAbout__14"
                  defaultMessage={"Problem-solving skills, puzzle games"}
                />
              </p>

              <p>
                <span className="activites activites3"></span>

                <FormattedMessage
                  id="OurCenterAbout__15"
                  defaultMessage={"Communication skills, interaction sessions"}
                />
              </p>

              <p>
                <span className="activites activites4"></span>

                <FormattedMessage
                  id="OurCenterAbout__16"
                  defaultMessage={"Sports, yoga and dance parties"}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCenterPlanPrograms;
