import React from "react";
import "./GetOurApps.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const GetOurApps = () => {
  return (
    <div className="getOurApps">
      <div className="leftGET">
        <div className="toy1 toy"></div>
        <div className="toy2 toy"></div>
        <div className="toy3 toy"></div>
        <div className="toy4 toy"></div>

        <div className="leftGetContainer">
          <div className="leftGETup">
            <p>
              <FormattedMessage id="goa1" />{" "}
              <span className="yellowA">
                <FormattedMessage id="goa2" />
              </span>{" "}
              <FormattedMessage id="goa3" />
            </p>
          </div>
          <p className="orangeText">
            <FormattedMessage id="goa4" />
          </p>
          <div className="leftGETmiddle">
            <p>
              <FormattedMessage id="goa5" />
            </p>
          </div>

          <div className="getApss">
            <Link className="getAppsAppG gaga"></Link>
            <Link className="getAppsAppI gaga"></Link>
          </div>
        </div>
      </div>
      <div className="rightGET">
        <div className="mobileImg"></div>
      </div>
    </div>
  );
};

export default GetOurApps;
