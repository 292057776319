import React from 'react';
import './MedicalCare.scss';
import Navbar from '../../components/shared/Navbar/Navbar';
import Footer from '../../components/shared/Footer/Footer';
import { FormattedMessage } from 'react-intl';

const MedicalCare = () => {
  return (
    <div className='MedicalCare'>
      <Navbar />
      <div className="WeekEndStay__inside MedicalCare__inside">
        <div className="WeekEndStay__1 ">
          <div className="WeekEndStay__1__filter "></div>
          <h1 className="ourServiceTitleBg">
            <FormattedMessage id='MedicalCare__1' defaultMessage='Medical Care' />
          </h1>

          <p className="ourServiceText">
            <FormattedMessage
              id='MedicalCare__2'
              defaultMessage='The health and safety of your child are paramount'
            />
          </p>
        </div>

        <div className="WeekEndStay__2 NightStay__2">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='MedicalCare__3' defaultMessage='Maintaining a healthy environment' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='MedicalCare__4'
                defaultMessage="We offer an on-site medical care service as part of our commitment to the well-being of every child in our care. Staffed by qualified healthcare professionals, our centers is equipped to handle a wide range of health-related needs, ensuring peace of mind for parents and a safe environment for children."
              />
            </p>
          </div>

          <div className="right">
            <div className="rightInside rightInside1 ">
              <div className="MedicalCare__star1"></div>
            </div>
          </div>
        </div>

        <div className="WeekEndStay__3 ">
          <div className="left">
            <div className="rightInside leftInside3">
              <div className="MedicalCare__star2"></div>
            </div>
          </div>

          <div className="right">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='MedicalCare__5' defaultMessage='Wellness education' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='MedicalCare__6'
                defaultMessage="Our on-site medical care goes beyond just responding to immediate needs; it encompasses a proactive approach to wellness. Regular wellness education are integral parts of our program. We collaborate closely with parents to manage any existing health conditions, ensuring that every child receives personalized care according to their health needs."
              />
            </p>
          </div>
        </div>


        <div className="WeekEndStay__4 ">
          <div className="left">
            <h1 className='ourServiceTitle'>
              <FormattedMessage id='MedicalCare__7' defaultMessage='Empowering healthy choices' />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='MedicalCare__8'
                defaultMessage="Education is a powerful tool for promoting long-term health. Our medical care service includes health education sessions tailored to different age groups, covering topics such as hygiene, nutrition, physical activity, and emotional well-being. By integrating health education into our curriculum, we empower children to make informed choices about their health and develop lifelong healthy habits."
              />
            </p>
          </div>

          <div className="right ">
            <div className="rightInside rightInside3 ">
              <div className="MedicalCare__star3"></div>
            </div>
          </div>
        </div>

        <div className="WeekEndStay__3 ">
          <div className="left">
            <div className="rightInside leftInside4 ">
              <div className="MedicalCare__star4"></div>
            </div>
          </div>

          <div className="right">
            <h1 className='ourServiceTitle'>
              <FormattedMessage
                id='MedicalCare__9'
                defaultMessage='Specialized child psychology consultations'
              />
            </h1>

            <p className="ourServiceText">
              <FormattedMessage
                id='MedicalCare__10'
                defaultMessage="Our child psychologists specialize in a range of services designed to support children's mental health. Our approach to child psychology is collaborative, involving parents, educators, and other caregivers in the process. We believe that a supportive network is essential for the successful development and implementation of any intervention or strategy. Regular meetings and updates ensure that everyone involved in the child's life is equipped to provide consistent, informed support."
              />
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MedicalCare;
