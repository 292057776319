import React from "react";
import "./OurPage.scss";
import { ourTeamTeacher } from "./OurTeamTeachers";
import { ourTeamDirectors } from "./OurTeamDirectors";
import { ourTeamMedical } from "./OurTeamMedical";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const OurPage = () => {
  return (
    <div className="OurPage">

      <div className="OurPage__top">
        <div className="left">
          

          <div className="firstImage">
            <div className="secondImage"></div>
            <div className="thirdImage"></div>

              <div className='starYellowkkc kkcicon'></div>
              <div className='starGreenkkc kkcicon'></div>
              <div className='kkcWave kkcicon'></div>
              <div className='kkcMagnet kkcicon'></div>
          </div>
        </div>

        <div className="right">
          <div className="kkclD makeFontBiger">
              <p>
              <FormattedMessage id='OurPage__1'/>
              </p>
          </div>

          <div className='kkcHeading'>
              <p>
                <span className='yellowA'>
                KosovaKids 
                </span>
              <FormattedMessage id='OurPage__2'/>
              </p>
          </div>

          <div className="kkclD">
              <p>
              <FormattedMessage id='OurPage__3'/>
              </p>
          </div>

          <div className="OurPage__footer">
            <Link to="">
              <FormattedMessage id='OurPage__4'/>
            </Link>
          </div>
        </div>
      </div>

      <div className="OurPage__inside">
        <div className="ourPage__1">
          <div className="aboutUsHeader">
            <p>
            <FormattedMessage id='OurPage__5'/>
            </p>
          </div>

          <div className="ourTeamCards">
            {ourTeamDirectors.map((ot, index) => {
              return (
                <Link className="ourTeamCard" key={index}>
                  <div className='circleBgOt' style={
                      {
                            backgroundColor: `${ot.circle}`, 
                      }} 
                  ></div>
                  <div className="personImgHolder">
                    <div
                      className="personImg"
                      style={{ content: `url(${ot.person})` }}
                    ></div>
                  </div>
                  <div className="personRole">
                    <p>{ot.role}</p>
                  </div>
                  <div className="personName">
                    <p>{ot.name}</p>
                  </div>
                  <div className="otCardSocials">
                    {ot.socials.map((ots, index2) => {
                      return (
                        <Link
                          className="otsocial"
                          style={{ content: `url(${ots.social})` }}
                          key={index2}
                        ></Link>
                      );
                    })}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="ourPage__2">
          <div className="aboutUsHeader">
            <p>
            <FormattedMessage id='OurPage__6'/>
            </p>
          </div>

          <div className="ourTeamCards">
            {ourTeamTeacher.map((ot, index) => {
              return (
                <Link className="ourTeamCard" key={index}>
                  <div className='circleBgOt' style={
                      {
                            backgroundColor: `${ot.circle}`, 
                      }} 
                  ></div>
                  <div className="personImgHolder">
                    <div
                      className="personImg"
                      style={{ content: `url(${ot.person})` }}
                    ></div>
                  </div>
                  <div className="personRole">
                    <p>{ot.role}</p>
                  </div>
                  <div className="personName">
                    <p>{ot.name}</p>
                  </div>
                  <div className="otCardSocials">
                    {ot.socials.map((ots, index2) => {
                      return (
                        <Link
                          className="otsocial"
                          style={{ content: `url(${ots.social})` }}
                          key={index2}
                        ></Link>
                      );
                    })}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="ourPage__3">
          <div className="aboutUsHeader">
            <p>
            <FormattedMessage id='OurPage__7'/>
            </p>
          </div>

          <div className="ourTeamCards">
            {ourTeamMedical.map((ot, index) => {
              return (
                <Link className="ourTeamCard" key={index}>
                  <div className='circleBgOt' style={
                      {
                            backgroundColor: `${ot.circle}`, 
                      }} 
                  ></div>
                  <div className="personImgHolder">
                    <div
                      className="personImg"
                      style={{ content: `url(${ot.person})` }}
                    ></div>
                  </div>
                  <div className="personRole">
                    <p>{ot.role}</p>
                  </div>
                  <div className="personName">
                    <p>{ot.name}</p>
                  </div>
                  <div className="otCardSocials">
                    {ot.socials.map((ots, index2) => {
                      return (
                        <Link
                          className="otsocial"
                          style={{ content: `url(${ots.social})` }}
                          key={index2}
                        ></Link>
                      );
                    })}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>


      <div className="OurPage__footer">
        <Link to="">
        <FormattedMessage id='OurPage__4'/>
        </Link>
      </div>



    </div>
  );
};

export default OurPage;
