import React from "react";
import "./FoodAndCare.scss";
import { FormattedMessage } from "react-intl";

const FoodAndCare = (props) => {
  return (
    <div className="FoodAndCare">
      <div className="wwoUp">
        <p>
          <FormattedMessage id="FoodAndCare__1" defaultMessage="Food and" />
          <span className="offerRed">&nbsp;
            <FormattedMessage id="FoodAndCare__2" defaultMessage="care" />
          </span>
        </p>
      </div>

      <div className="FoodAndCare__inside">
        <div className="food__card">
          <div className="cardTop cardtop1"></div>
          <h2>
            <FormattedMessage id="FoodAndCare__3" defaultMessage="Healthy meals" />
          </h2>

          <p>
            {props.healthyMeal1}
          </p>

          <p>
            {props.healthyMeal2}
          </p>
        </div>

        <div className="food__card">
          <div className="cardTop cardtop2"></div>
          <h2>
            <FormattedMessage id="FoodAndCare__4" defaultMessage="Kids wellbeing" />
          </h2>

          <p>
            {props.healthyMeal3}
          </p>

          <p>
            {props.healthyMeal4}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoodAndCare;
