import React from 'react'
import './FreeCoursesCard1.scss'
import { Link } from 'react-router-dom'

const FreeCoursesCard1 = ({ bgImage, smallTitle, smallTitleColor, title, age, location, duration, time }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

    const smallTitleColorDiv = {
        backgroundColor: `${smallTitleColor}`
    }

  return (
    <div className='FreeCoursesCard1'>
        <div className="top" style={bgImageDiv}>
            <div className="cardTop" style={smallTitleColorDiv}>
            {smallTitle}
            </div>
        </div>

        <div className="bottom">
            <p className='bottomTItle'>
            {title}
            </p>
            <div className="FreeCoursesCard1__line"></div>

            <div className="bottom__inside">
                <div className="bottom__age">
                    <div className="ageIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Age group
                        </span>

                        <p>
                        {age}
                        </p>
                    </div>
                </div>

                <div className="bottom__age">
                    <div className="locationIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Location
                        </span>

                        <p>
                        {location}
                        </p>
                    </div>
                </div>

                <div className="bottom__age">
                    <div className="durationIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Duration
                        </span>

                        <p>
                        {duration}
                        </p>
                    </div>
                </div>

                <div className="bottom__age">
                    <div className="timeIcon"></div>

                    <div className='bottomRight'>
                        <span>
                        Time
                        </span>

                        <p>
                        {time}
                        </p>
                    </div>
                </div>

            </div>

            <div className="bottom__last">
                <Link className='FreeCoursesCard1__A'>
                Enroll
                </Link>

                <h2 className='freeCourseH2White'>
                    Free
                </h2>
            </div>
        </div>
    </div>
  )
}

export default FreeCoursesCard1