import cicon1 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon1.svg"
import cicon2 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon2.svg"
import cicon3 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon3.svg"
import cicon4 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon4.svg"
import cicon5 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon5.svg"
import cicon6 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon6.svg"
import cicon7 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon7.svg"
import cicon8 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon8.svg"

import carouselImg1 from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/carouselItem.webp';
import profile1 from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/carouselProfile.webp';
import clockIcon from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/clock.svg';
import { FormattedMessage } from "react-intl"

export const coursesData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        aicon : cicon1,
        title: <FormattedMessage id='coursesData__1' />,
        toWhere: "/ourCourses?categories=Robotics"
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        aicon : cicon2,
        title: <FormattedMessage id='coursesData__2' />,
        toWhere: "/ourCourses?categories=Soft+Skills"
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        aicon : cicon3,
        title: <FormattedMessage id='coursesData__3' />,
        toWhere: "/ourCourses"
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        aicon : cicon4,
        title: <FormattedMessage id='coursesData__4' />,
        toWhere: "/ourCourses"
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        aicon : cicon5,
        title: <FormattedMessage id='coursesData__5' />,
        toWhere: "/ourCourses"
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        aicon : cicon6,
        title: <FormattedMessage id='coursesData__6' />,
        toWhere: "/ourCourses"
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        aicon : cicon7,
        title: <FormattedMessage id='coursesData__7' />,
        toWhere: "/ourCourses?categories=Science"
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        aicon : cicon8,
        title: <FormattedMessage id='coursesData__8' />,
        toWhere: "/ourCourses"
    }
]


export const carouselDataC = [
    {
        carouselImg : carouselImg1,
        programming: <FormattedMessage id='coursesData__9' />,
        price : "$ 100/mo",
        profile: profile1,
        instructor: <FormattedMessage id='coursesData__10' />,
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: <FormattedMessage id='coursesData__11' />,
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: <FormattedMessage id='coursesData__9' />,
        price : "$ 100/mo",
        profile: profile1,
        instructor: <FormattedMessage id='coursesData__10' />,
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: <FormattedMessage id='coursesData__11' />,
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: <FormattedMessage id='coursesData__9' />,
        price : "$ 100/mo",
        profile: profile1,
        instructor: <FormattedMessage id='coursesData__10' />,
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: <FormattedMessage id='coursesData__11' />,
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: <FormattedMessage id='coursesData__9' />,
        price : "$ 100/mo",
        profile: profile1,
        instructor: <FormattedMessage id='coursesData__10' />,
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: <FormattedMessage id='coursesData__11' />,
        time: "16:00 - 18:00"
    }
]