import React from 'react'
import './BlogCard.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const BlogCard = (props) => {

    const chnageBorderColor = {
        borderColor: `${props.cardColor}`,
        color: `${props.cardColor}`
    }

    const bgImageDiv = {
        backgroundImage: `url(${props.blogImage})`
    }

  return (
    <div className='BlogCard'>
        <div className="top" style={bgImageDiv}>

        </div>

        <div className="bottom">
            <div className="blogCard1">
                <span>
                {props.timeWhen}
                </span>

                <span className="cardBlog" style={chnageBorderColor}>
                {props.card}
                </span>
            </div>

            <p>
            {props.title}
            </p>

            <Link to="">
                <FormattedMessage 
                    id='blogcard__1'
                    defaultMessage={"Read more"}
                />
            </Link>
        </div>
    </div>
  )
}

export default BlogCard