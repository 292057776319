import social1 from '../../../assets/images/Footer-images/facebook.svg';
import social2 from '../../../assets/images/Footer-images/instagram.svg';
import social3 from '../../../assets/images/Footer-images/snapchat.svg';
import social4 from '../../../assets/images/Footer-images/twitter.svg';
import social5 from '../../../assets/images/Footer-images/linkedin.svg';
import social6 from '../../../assets/images/Navbar-images/tiktok.png';



export const footerSocials = [
    {
        to : "https://www.facebook.com/kosovakidscom",
        social : social1
    },
    {
        to : "https://www.instagram.com/kosovakids/",
        social : social2
    },
    {
        to: "",
        social : social3
    },
    {
        to: "https://x.com/Kosovakids",
        social : social4
    },
    {
        to: "https://linkedin.com/kosovakids",
        social : social5
    },
    {
        to: "https://tiktok.com/@kosovakids",
        social : social6
    }
]