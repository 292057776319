import React from 'react'
import './FunActivities.scss'
import { FormattedMessage } from 'react-intl'

const FunActivities = () => {
  return (
    <div className='FunActivities'>
        <div className="FunActivities__bg">
            <div className="star"></div>
            <div className="wave"></div>
            <div className="magnetFun"></div>
        </div>

        <div className="FunActivities__inside">
            <h2 className="fun">
                <span className="yellowFun">
                    <FormattedMessage
                      id="FunActivities__1"
                      defaultMessage="Fun"
                    />
                </span>
                &nbsp;&nbsp;
                <FormattedMessage
                  id="FunActivities__2"
                  defaultMessage="activities"
                />
            </h2>
            
            <div className="inside">
                <div className="fun__card">
                    <div className="funImage funImage1"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__3"
                          defaultMessage="Outdoor Exploration Adventures"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__4"
                          defaultMessage="Connecting kids with nature. Regular activities in our garden and nearby parks"
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage2"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__5"
                          defaultMessage="Technology Integration"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__6"
                          defaultMessage="Utilizing age-appropriate technology to enhance learning experiences."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage3"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__7"
                          defaultMessage="Yoga Sessions"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__8"
                          defaultMessage="Incorporating mindfulness practices and yoga to teach children about self-regulation, relaxation, and body awareness."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage4"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__9"
                          defaultMessage="Arts & crafts"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__10"
                          defaultMessage="A creative space where children can explore various art forms, including painting, crafts, and other type of arts."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage5"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__11"
                          defaultMessage="Music exploration"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__12"
                          defaultMessage="Introducing children to a variety of musical instruments and genres, encouraging musical experimentation and enjoyment."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage6"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__13"
                          defaultMessage="Community Connection"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__14"
                          defaultMessage="Engaging with the local community through projects like 'Plant a Tree' day, visits to senior homes, and more."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage7"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__15"
                          defaultMessage="Science Projects"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__16"
                          defaultMessage="Involving children into safe experiments, fostering a sense of wonder and discovery about the world."
                        />
                    </p>
                </div>

                <div className="fun__card">
                    <div className="funImage funImage8"></div>
                    <h3 className="funTitle">
                        <FormattedMessage
                          id="FunActivities__17"
                          defaultMessage="Multilanguage"
                        />
                    </h3>
                    <p className="funDescription">
                        <FormattedMessage
                          id="FunActivities__18"
                          defaultMessage="At our centers kids can learn multiple languages like English, German and more. Developing new skills at young age."
                        />
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FunActivities