import React, { useState } from "react";
import { FormattedMessage } from "react-intl"; // assuming you're using react-intl for message formatting
import { FiPhone } from "react-icons/fi";
import { IoIosCard } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";
import { PiGlobeHemisphereWestFill } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import './Payment.scss'
import { CiUser } from "react-icons/ci";

const Payment = () => {
  const [isCompany, setIsCompany] = useState(false)

  return (
    <div className="Payment Amount">
      <div className="Amount__1">
        <p className="mediumText">
          <FormattedMessage
            id="Payment__1"
            defaultMessage="Payment method"
          />
        </p>
      </div>

      <div className="Amount__6 Payment__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="cardNumber">
              <FormattedMessage
                id="Payment__2"
                defaultMessage="Card Number"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <IoIosCard />
              </span>
              <input
                type="number"
                id="cardNumber"
                placeholder="Card Number"
              />
            </div>
          </div>

          <div className="otherHalf">
            <div>
              <label htmlFor="expireData">
                <FormattedMessage
                  id="Payment__3"
                  defaultMessage="Expire Date"
                />
              </label>
              <div className="spanAndInput people">
                <span id="people">
                  <LuCalendarDays />
                </span>
                <input type="month" id="expireData" placeholder="MM/YY" />
              </div>
            </div>

            <div>
              <label htmlFor="cvc">
                <FormattedMessage id="Payment__4" defaultMessage="CVC" />
              </label>
              <div className="spanAndInput people">
                <span id="people">
                  <IoIosCard />
                </span>
                <input type="number" id="cvc" placeholder="CVC" min={0} max={999} />
              </div>
            </div>
          </div>
        </div>

        <div className="hideProfile" >
          <input type="checkbox" name="" id="hideProfile" />
          <label htmlFor="hideProfile" onClick={() => setIsCompany(!isCompany)}>
            <FormattedMessage
              id="Payment__5"
              defaultMessage="Is this a company credit card?"
            />
          </label>
        </div>
      </div>

      {
        isCompany && (
          <div className="Amount__6 Payment__6">
            <div className="insideAmount">
              <div>
                <label htmlFor="companyName">
                  <FormattedMessage
                    id="Payment__6"
                    defaultMessage="Company"
                  />
                </label>
                <div className="spanAndInput people">
                  <span id="people">
                    <MdOutlineEmail />
                  </span>
                  <input type="text" id="companyName" placeholder="Write company name" />
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className="Amount__6 Payment__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="firstName">
              <FormattedMessage
                id="Payment__7"
                defaultMessage="Billing First Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <MdOutlineEmail />
              </span>
              <input type="text" id="firstName" placeholder="Write first name" />
            </div>
          </div>

          <div>
            <label htmlFor="lastName">
              <FormattedMessage
                id="Payment__8"
                defaultMessage="Billing Last Name"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input
                type="text"
                id="lastName"
                placeholder="Write last name"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="Amount__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="billingAddress">
              <FormattedMessage
                id="Payment__9"
                defaultMessage="Billing Address"
              />
            </label>
            <div className="spanAndInput people">
              <span id="people">
                <IoLocationOutline />
              </span>
              <input type="email" id="billingAddress" placeholder="Write your billing address" />
            </div>
          </div>
        </div>
      </div>

      <div className="makeSomeGray">
        <input type="checkbox" id="termAndService" />
          <label htmlFor="termAndService">
          <FormattedMessage
            id="Payment__10"
            defaultMessage="By donating, you agree to our"
          />
          <span className="yellowA">
            &nbsp;Terms of Service&nbsp;
          </span>
          <FormattedMessage
            id="Payment__11"
            defaultMessage="and"
          />
          <span className="yellowA">
            &nbsp;Privacy Policy.
          </span>
        </label>
      </div>
    </div>
  );
};

export default Payment;
