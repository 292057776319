import cicon1 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon1.svg"
import cicon2 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon2.svg"
import cicon3 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon3.svg"
import cicon4 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon4.svg"
import cicon5 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon5.svg"
import cicon6 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon6.svg"
import cicon7 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon7.svg"
import cicon8 from "../../../../../assets/images/Navbar-images/shopMegamenu-images/cicon8.svg"

import carouselImg1 from '../../../../../assets/images/Navbar-images/shopMegamenu-images/carouselItem1.webp'
import carouselImg2 from '../../../../../assets/images/Navbar-images/shopMegamenu-images/carouselItem2.webp'
import { FormattedMessage } from "react-intl"

export const shopData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        aicon : cicon1,
        title: <FormattedMessage id='extraShop__4'/>
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        aicon : cicon2,
        title: <FormattedMessage id='extraShop__5'/>
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        aicon : cicon3,
        title: <FormattedMessage id='extraShop__6'/>
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        aicon : cicon4,
        title: <FormattedMessage id='extraShop__7'/>
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        aicon : cicon5,
        title: <FormattedMessage id='extraShop__8'/>
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        aicon : cicon6,
        title: <FormattedMessage id='extraShop__9'/>
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        aicon : cicon7,
        title: <FormattedMessage id='extraShop__10'/>
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        aicon : cicon8,
        title: <FormattedMessage id='extraShop__11'/>
    }
]

export const carouselData = [
    {
        carouselImg : carouselImg1,
        itemName: "KosovaKids Hoodie",
        itemPrice: "$ 12.30"
    },
    {
        carouselImg : carouselImg2,
        itemName: "KosovaKids T-shirt",
        itemPrice: "$ 12.30"
    },
    {
        carouselImg : carouselImg1,
        itemName: "KosovaKids Hoodie",
        itemPrice: "$ 12.30"
    },
    {
        carouselImg : carouselImg2,
        itemName: "KosovaKids T-shirt",
        itemPrice: "$ 12.30"
    }
]