import React, { useState } from 'react'
import './Footer.scss';
import { Link } from 'react-router-dom';
import { footerLinks } from './FooterData';
import { IoIosArrowDown } from "react-icons/io";
import { footerSocials } from './SocialsData';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import ColorSwitch2 from './ColorSwitch2';
import { FormattedMessage } from "react-intl"
import ChangingMode from '../ChangingMode';

const Footer = (props) => {
    const [theme,setTheme] = useState(false);

    const toggleTheme = () => {
        setTheme(!theme)
    }

  return (
    <div className='footer'>
        <div className='footerLogo'>
            <div className='fl'></div>
        </div>
        <div className='fapps'>
            <div className='leftFapps'>
                <p>
                <FormattedMessage id="footerText"  />
                    </p>
            </div>
            <div className='rightFApps'>
                <p>
                <FormattedMessage id="footerApp"  />
                    </p>
                <div className='appsGP'>
                    <Link className='mobF gP'></Link>
                    <Link className='mobF appS'></Link>
                </div>
            </div>
        </div>

        <div className='footerLinks'>
            <div className='leftFooterLinks'>
                {
                    footerLinks.map((fl,index) => {
                        return(
                            <div className='footerLink' key={index}>
                                <p className='flTitle'>{fl.title}</p>
                                {
                                    fl.links.map((nl,indexn) => {
                                        return(
                                            <Link key={indexn} to={nl.whereTo}>{nl.link}</Link>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className='rightFooterMiddle'>
                <p className='stonF'> <FormattedMessage id="F36"  /></p>
                <div className='footerInput'>
                    <div className='enveIconFI'></div>
                    <input placeholder='Enter your email adress' type='text' />
                    <div className='subscribeButton button'>
                        <Link>Subscribe</Link>
                    </div>
                   
                </div>
                <div className='footerInfo'>
                    <div className='fMail'>
                        <div className='fMailIc'></div>
                        <p>info@kosovakids.com</p>
                    </div>
                    <div className='fPhone'>
                    <div className='fPhoneIc'></div>
                        <p>+383 (45) 476 560</p>
                    </div>
                </div>
            </div>
        </div>


        <div className='downFooter'>
            <div className='leftDownFooter'>
                <div className='copyrightFooter'>
                    <p>KosovaKids 2024 © All rights reserved.</p>
                </div>
                <div className='cookiesPolicyF'>
                    <Link to="/cookie-policy"> Cookie Policy</Link>
                    <p>|</p>
                    <Link to="/privacy-policy"> Privacy Policy</Link>
                </div>
            </div>
            <div className='rightDownFooter'>
                <div className='footerSocials'>
                    {
                        footerSocials.map((s,index) => {
                            return(
                                <Link className='social' to={s.to} key={index} style={{ content: `url(${s.social})` }}></Link>
                            )
                        })
                    }
                </div>
                <div className='footerSettings' onMouseEnter={toggleTheme} onMouseLeave={() => setTheme(false)}>
                    <div className='settingsIcon'></div>
                    <IoIosArrowDown  className='settingsArrow'/>
                    {
                        theme && (
                            <ColorSwitch2/>
                        )
                    }
                    
                </div>
                <ChangingMode/>
                <SelectLanguage
                    setLanguage={props.setLanguage}
                    language={props.language}
                />
                
            </div>
        </div>
    </div>
  )
}

export default Footer
