import cicon1 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon1.svg"
import cicon2 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon2.svg"
import cicon3 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon3.svg"
import cicon4 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon4.svg"
import cicon5 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon5.svg"
import cicon6 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon6.svg"
import cicon7 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon7.svg"
import cicon8 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon8.svg"

import carouselImg1 from '../../../../../assets/images/Navbar-images/servicesMegamenu-images/carouselItem.webp';
import carouselImg2 from '../../../../../assets/images/Navbar-images/servicesMegamenu-images/carouselImg2.webp';
import carouselImg3 from '../../../../../assets/images/Navbar-images/servicesMegamenu-images/carouselImg3.webp';
import { FormattedMessage } from "react-intl"


export const servicesData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        aicon : cicon1,
        title: <FormattedMessage id="servicesData__1"/>,
        towher: "dayCare"
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        aicon : cicon2,
        title: <FormattedMessage id="servicesData__2"/>,
        towher: "stayAfterRegular"
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        aicon : cicon3,
        title: <FormattedMessage id="servicesData__3"/>,
        towher: "weekEndStay"
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        aicon : cicon4,
        title: <FormattedMessage id="servicesData__4"/>,
        towher: "medicalCare"
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        aicon : cicon5,
        title: <FormattedMessage id="servicesData__5"/>,
        towher: "nightStay"
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        aicon : cicon6,
        title: <FormattedMessage id="servicesData__6"/>,
        towher: "freeCourses"
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        aicon : cicon7,
        title: <FormattedMessage id="servicesData__7"/>,
        towher: "halfDayCare"
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        aicon : cicon8,
        title: <FormattedMessage id="servicesData__8"/>,
        towher: "outdoorActivities"
    }
]

export const SCarouselData = [
    {
        carouselImg : carouselImg1,
        title: <FormattedMessage id="servicesData__9"/>,
        des: <FormattedMessage id="servicesData__10"/>,
    },
    {
        carouselImg : carouselImg2,
        title: <FormattedMessage id="servicesData__9__2"/>,
        des: <FormattedMessage id="servicesData__10__2"/>,
    },
    {
        carouselImg : carouselImg3,
        title: <FormattedMessage id="servicesData__9__3"/>,
        des: <FormattedMessage id="servicesData__10__3"/>,
    }
    
]